/* eslint @typescript-eslint/no-var-requires: "off" */
// line above is for ignoring no-require at line 24-27.
import _, { Dictionary } from "lodash";
import config from "../config";
import { version } from "../../package.json";
import i18n from "../i18n";
import store from "../store/store";
import { FirebaseService } from "../services/ServiceControler";
import {
  Word,
  RoomData,
  Transcript,
  RoomAsset,
  AssetOffset,
  Event,
  ExportOptions,
  TranscriptType,
  PreviewFormat,
} from "../models";
import PizZip from "pizzip";
import "pizzip/dist/pizzip-utils";
import Docxtemplater from "docxtemplater";
import { saveAs } from "file-saver";
import TimeService from "./TimeService";
import SoundManager from "./SoundManager";
import CloudFunctionsService from "../services/CloudFunctionsService";
import { JobData, JobDocument } from "../models/job";
import { Annotation, Annotations } from "../models/annotations";
import {
  SpeakerRange,
  JobRange,
  SubtitlesTranslationRange,
  SubtitlesRange,
  RangeValidationConfig,
} from "../models/range";
import TrackingService, { UserEventSumup, WorkTime } from "./TrackingService";
import { clearLocalStorage } from "./SettingService";
import { generateId } from "../utils/generators";
import TranslationService from "./TranslationService";
import Logger from "./Logger";

import { format } from "date-fns";
import { LoggedInUser } from "../models/user";
import { JobMarkers } from "../models/markers";
import { JobType, jobTypes } from "../models/jobTypes";
import rangeValidations from "../pages/Editor/validations/rangeValidations";
import axios from "axios";
import { downloadContent } from "../utils/webHelpers";

const Diff = require("diff");
const docxTemplateLTR = require("../assets/docx-templates/defLTR.docx").default;
const docxTemplateWithTimesLTR = require("../assets/docx-templates/defWithTimesLTR.docx");
const ltrIntervalsTemplate = require("../assets/docx-templates/ltr_intervals.docx")
  .default;
const rtlMinutesTemplate = require("../assets/docx-templates/rtl_minutes.docx")
  .default;
const rtlMinutesTimesTemplate = require("../assets/docx-templates/rtl_minutes_times.docx")
  .default;
const rtlPagesTemplate = require("../assets/docx-templates/rtl_pages.docx")
  .default;
const rtlPagesTimesTemplate = require("../assets/docx-templates/rtl_pages_times.docx")
  .default;
const rtlIntervalsTemplate = require("../assets/docx-templates/rtl_intervals.docx")
  .default;

const templates = {
  rtl: {
    minutes: rtlMinutesTemplate,
    minutes_times: rtlMinutesTimesTemplate,
    pages: rtlPagesTemplate,
    pages_times: rtlPagesTimesTemplate,
    intervals: rtlIntervalsTemplate,
  },
  ltr: {
    minutes: docxTemplateLTR,
    minutes_times: docxTemplateWithTimesLTR,
    pages: docxTemplateLTR,
    pages_times: docxTemplateWithTimesLTR,
    intervals: ltrIntervalsTemplate,
  },
};

const logger = Logger("CloudFunctionsService");

const getJob = async (id: string, options: any) => {
  try {
    const jobDoc: JobDocument = await FirebaseService.getJobData(id);
    const ranges: JobRange[] = (await FirebaseService.getJobRanges(
      id,
      options
    )) as JobRange[];
    const markers: JobMarkers = await FirebaseService.getJobMarkers(id);

    let assets: RoomAsset[] = [];
    let offsets;
    if (!options.noAssets) {
      const jobAssets = await FirebaseService.getRoomAssets(id);

      assets = jobAssets.roomAssets;
      offsets = jobAssets.offsets;
    }

    const formattedJob = await formatJobData({
      jobDoc,
      ranges,
      assets,
      offsets,
      markers,
    });
    if (_.isEmpty(formattedJob.ranges)) {
      if (jobTypes[formattedJob.jobType].editMode === "protocol") {
        const words = (await FirebaseService.getRoomWords(
          id,
          options
        )) as Word[];
        const speakers = getSpeakersFromWords(words);
        formattedJob.speakers = speakers;
        formattedJob.ranges = generateJobRanges({
          ...formattedJob,
          words,
          speakers,
        });
      }

      if (jobTypes[formattedJob.jobType].editMode === "subtitles") {
        const words = (await FirebaseService.getRoomWords(
          id,
          options
        )) as Word[];
        formattedJob.ranges = await _generateSubtitlesRangesFromWords(words);
      }

      if (
        formattedJob.jobType === "subtitles-translation" &&
        _.get(formattedJob, "translation.type") === "target"
      ) {
        formattedJob.ranges = await TranslationService.getRangesForSubTrans(id);
      }
    }
    return formattedJob;
  } catch (err) {
    console.log(err);
    throw new Error(err);
  }
};

const getJobs = async (jobIds: string[]) => {
  const jobs = await Promise.all(
    _.map(jobIds, async (jobId) => {
      const job = await getJob(jobId, {});
      return job;
    })
  );

  return jobs;
};

const getUniqMergedJobs = async (jobIds: string[]) => {
  const uniqMergedJobsById: any = {};
  const uniqMergedJobs: any = [];

  await Promise.all(
    jobIds.map(async (jobId) => {
      const job: any = await FirebaseService.getJobData(jobId);
      if (!job.split) {
        uniqMergedJobsById[jobId] = "single";
      } else if (job.split.parent) {
        uniqMergedJobsById[jobId] = "split";
      } else {
        uniqMergedJobsById[job.split.parentId] = "split";
      }
    })
  );

  await Promise.all(
    _.keys(uniqMergedJobsById).map(async (jobId) => {
      let job;
      const shouldMerge = uniqMergedJobsById[jobId] === "split";
      if (shouldMerge) {
        job = await getMergedJob(jobId);
      } else {
        job = await getJob(jobId, { noAssets: true });
      }
      uniqMergedJobs.push(job);
    })
  );

  return uniqMergedJobs;
};

const _generateSubtitlesRangesFromWords = (words: Word[]) => {
  const ranges: SubtitlesRange[] = [];

  const wordsByRangesIndex = _.groupBy(words, "range_ix");
  let wordCount = 0;
  for (const rangeWords of _.values(wordsByRangesIndex)) {
    if (!rangeWords) continue;

    const six = wordCount;
    const eix = wordCount + rangeWords.length - 1;
    const range = {
      id: generateId("sr_"),
      six,
      eix,
      st: rangeWords[0].start,
      et: rangeWords[rangeWords.length - 1].end,
      words: rangeWords,
      type: "subtitles",
    } as SubtitlesRange;

    ranges.push(range);
    wordCount = wordCount + rangeWords.length - 1;
  }

  return ranges;
};

const saveWorkTime = async (job: JobData, addedWorkTime: number) => {
  const totalWorkTime = getWorkTime(job, addedWorkTime);
  await FirebaseService.saveWorkTime(job.roomId, totalWorkTime);
};

const updateRoomData = async (
  addedWorkTime: number,
  options?: Partial<RoomData>,
  isChanged?: boolean
): Promise<void> => {
  const roomData = store.getState().roomStore.roomData;

  roomData.workTime = getWorkTime(roomData, addedWorkTime);
  TrackingService.clearEvents();

  await FirebaseService.saveMeeting({ ...roomData, ...options });

  if (!isChanged) return;
  CloudFunctionsService.wordsSnapshot({
    roomId: roomData.roomId,
    prefix: "save",
  });
};

const saveJob = async (
  job: JobData,
  options: {
    user: LoggedInUser;
    sessionId?: string;
    saveMethod?: string;
    addedWorkTime?: number;
  }
): Promise<void> => {
  const totalWorkTime = getWorkTime(
    job,
    options.addedWorkTime ? options.addedWorkTime : 0
  );
  await FirebaseService.saveJob(
    { ...job, workTime: totalWorkTime },
    {
      userId: options.user.id,
      userName: options.user.username,
      sessionId: options.sessionId,
      saveMethod: options.saveMethod,
      version,
    }
  );

  TrackingService.reportEvent("job_save");
  TrackingService.clearEvents();

  CloudFunctionsService.jobSnapshot({
    jobId: job.roomId,
    filesToCopy: ["ranges.json", "ready.json"],
  });
};

const startLiveInterview = async (
  jobId: string,
  streamingState: JobData["streaming"]
): Promise<void> => {
  try {
    await CloudFunctionsService.stream.startLiveInterview(jobId);

    await FirebaseService.updateJob({
      id: jobId,
      streaming: streamingState,
    });

    TrackingService.reportEvent("start_live_interview");
  } catch (err) {
    logger.error("Failed to start live interview", err);
    throw err;
  }
};

const endLiveInterview = async (
  jobId: string,
  streamingState: JobData["streaming"]
): Promise<void> => {
  try {
    await CloudFunctionsService.stream.endLiveInterview(jobId);

    await FirebaseService.updateJob({
      id: jobId,
      streaming: streamingState,
    });

    TrackingService.reportEvent("end_live_interview");
  } catch (err) {
    logger.error("Failed to end live interview");
  }
};

const getAudioStream = async (jobId: string, fileName = "stream.mp3") => {
  const audioStreamFile = FirebaseService.storage.ref(
    `${FirebaseService.buckets.rooms}/${jobId}/${fileName}`
  );

  const audioStreamFileUrl = await audioStreamFile.getDownloadURL();

  const streamData = {
    name: audioStreamFile.name ? audioStreamFile.name : "",
    src: audioStreamFileUrl,
    type: "audio",
  } as RoomAsset;

  return streamData;
};

const saveAudit = async (
  jobData: RoomData | JobData,
  addedWorkTime: number
) => {
  const workTime = getWorkTime(jobData, addedWorkTime);
  FirebaseService.updateJobFields({ workTime: workTime }, jobData.roomId);
  TrackingService.clearEvents();
  return workTime;
};

const formatJobData = async ({
  jobDoc,
  ranges,
  assets,
  offsets,
  markers,
}: {
  jobDoc: JobDocument;
  ranges: JobRange[] | SubtitlesTranslationRange[];
  assets: RoomAsset[];
  words?: Word[];
  offsets?: AssetOffset;
  markers?: JobMarkers;
}) => {
  const transcriber =
    jobDoc.assignedTranscriber && !_.isString(jobDoc.assignedTranscriber)
      ? await jobDoc.assignedTranscriber.get()
      : null;
  const proofer =
    jobDoc.assignedProofer && !_.isString(jobDoc.assignedProofer)
      ? await jobDoc.assignedProofer.get()
      : null;

  const formattedJob = {
    roomId: jobDoc.id,
    name: jobDoc.name,
    speakers:
      jobTypes[jobDoc.jobType].editMode === "protocol"
        ? getSpeakersFromRanges(ranges as SpeakerRange[])
        : [""],
    deadline: jobDoc.deadline.toDate() as Date,
    clientDeadline: jobDoc.clientDeadline
      ? (jobDoc.clientDeadline as Date)
      : null,
    creationTime: jobDoc.creationTime.toDate(),
    meetingLength: jobDoc.meetingLength,
    jobType: jobDoc.jobType || "protocol",
    clientId: jobDoc.ownerId.id,
    price: jobDoc.price,
    prooferPrice: jobDoc.prooferPrice,
    proofer: jobDoc.assignedProofer,
    prooferName: proofer ? proofer.data()?.username : "",
    transcriber: jobDoc.assignedTranscriber,
    transcriberName: transcriber ? transcriber.data()?.username : "",
    status: jobDoc.status,
    translation: jobDoc.translation,
    lang: jobDoc.lang,
    process: {
      ...jobDoc.process,
      status: jobDoc.process?.status || "ready",
      progress: jobDoc.process?.progress || null,
      errors: jobDoc.process?.errors || [],
    },
    timeTrack: FirebaseService.getTimeTrack(jobDoc.timeTrack),
    editProgress: jobDoc.editProgress || 0,
    split: jobDoc.split,
    assets,
    offsets,
    ranges,
    markers,
    words: [],
    workTime: jobDoc.workTime,
    timeSynced: jobDoc.timeSynced ? jobDoc.timeSynced : false,
    notes: jobDoc.notes || "",
    streaming: jobDoc.streaming,
  };
  return formattedJob;
};

const getTempSpeakerName = (): string => {
  const tempSpeakerName = i18n.t("unidentified_speaker");
  return `${tempSpeakerName}-${(Math.random() * 10000).toFixed(0)}`;
};

const getMeetingWordsFromString = (
  meetingWords: Word[],
  newInputString: string,
  oldInputString: string,
  firstWordIndex: number,
  speaker: string,
  rangesCount: number
): Word[] => {
  const fullWordsArray = meetingWords.filter((i) => i.text !== "\n"); // Removing linebreaks
  const rangeNewWords = newInputString
    .trim()
    .split(" ")
    .filter((words) => words); // Removing whitespace
  const rangeOldWords = oldInputString
    .trim()
    .split(" ")
    .filter((word) => word); // Removing whitespace
  const rangeWordsArray = fullWordsArray.slice(
    firstWordIndex,
    firstWordIndex + rangeOldWords.length
  );

  const lastNewWordIndex = rangeNewWords.length - 1;
  const lastOldWordIndex = rangeOldWords.length - 1;

  let startI = 0;
  while (
    rangeNewWords[startI] === rangeOldWords[startI] &&
    !!rangeNewWords[startI]
  ) {
    startI++;
  }
  let endI = 0;
  while (
    rangeNewWords[lastNewWordIndex - endI] ===
      rangeOldWords[lastOldWordIndex - endI] && // Last new word == last old word
    !!rangeNewWords[lastNewWordIndex - endI] && // Last new word exists
    lastOldWordIndex - endI > startI && // Current iteration last word is the last new word
    lastNewWordIndex - endI > startI
  ) {
    endI++;
  }

  let indexToCopy = startI + firstWordIndex;
  if (startI > lastOldWordIndex && indexToCopy > 0) indexToCopy--;

  // // Old way of getting missing word times, before Diff
  // const wordToCopy = meetingWords[indexToCopy];
  // const changedWordsObjects: Word[] = rangeNewWords
  //   .slice(startI, rangeNewWords.length - endI)
  //   .map((str, i) => {
  //     const oldWord = rangeWordsArray[startI + i].text;
  //     const newWord = str;

  //     console.log(oldWord, newWord);
  //     const newWor = {
  //       ...wordToCopy,
  //       id: generateWordId(),
  //       speaker: speaker,
  //       text: str,
  //       word: str,
  //     };
  //     return newWor;
  //   });

  const newChangedWords = calculateNewWordsTimes(
    rangeWordsArray,
    rangeNewWords
  ).slice(startI, rangeNewWords.length - endI);

  const updatedMeetingWords: Word[] = fullWordsArray.slice(
    0,
    firstWordIndex + startI
  );
  updatedMeetingWords.push(...newChangedWords);
  let lastWord = fullWordsArray;
  lastWord = lastWord.slice(
    firstWordIndex +
      rangeOldWords.length -
      (endI === 0 && rangesCount === 0 ? -1 : endI)
  ); // When single speaker (rangesCount === 0) removing also last word (-1)
  updatedMeetingWords.push(...lastWord);

  return updatedMeetingWords;
};

const getRangeWordsFromString = (
  rangeWords: Word[],
  newInputString: string,
  oldInputString: string,
  rangesCount: number
): Word[] => {
  const fullWordsArray = rangeWords.filter((i) => i.text !== "\n"); // Removing linebreaks
  const rangeNewWords = newInputString
    .trim()
    .split(" ")
    .filter((words) => words); // Removing whitespace
  const rangeOldWords = oldInputString
    .trim()
    .split(" ")
    .filter((word) => word); // Removing whitespace

  const lastNewWordIndex = rangeNewWords.length - 1;
  const lastOldWordIndex = rangeOldWords.length - 1;

  let startI = 0;
  while (
    rangeNewWords[startI] === rangeOldWords[startI] &&
    !!rangeNewWords[startI]
  ) {
    startI++;
  }
  let endI = 0;
  while (
    rangeNewWords[lastNewWordIndex - endI] ===
      rangeOldWords[lastOldWordIndex - endI] && // Last new word == last old word
    !!rangeNewWords[lastNewWordIndex - endI] && // Last new word exists
    lastOldWordIndex - endI > startI && // Current iteration last word is the last new word
    lastNewWordIndex - endI > startI
  ) {
    endI++;
  }

  let indexToCopy = startI;
  if (startI > lastOldWordIndex && indexToCopy > 0) indexToCopy--;

  const newChangedWords = calculateNewWordsTimes(
    rangeWords,
    rangeNewWords
  ).slice(startI, rangeNewWords.length - endI);

  const updatedRangeWords: Word[] = fullWordsArray.slice(0, startI);
  updatedRangeWords.push(...newChangedWords);
  let lastWord = fullWordsArray;
  lastWord = lastWord.slice(
    rangeOldWords.length - (endI === 0 && rangesCount === 0 ? -1 : endI)
  ); // When single speaker (rangesCount === 0) removing also last word (-1)
  updatedRangeWords.push(...lastWord);

  return updatedRangeWords;
};

const getRangeWordsFromMultilineString = (
  range: JobRange,
  newInputString: string,
  oldInputString: string,
  rangesCount: number
): Word[] => {
  const rangeWords = range.words;
  const fullWordsArray = rangeWords.filter((i) => i.text !== "\n"); // Removing linebreaks

  let wordCount = 0;
  const lineBreaks = newInputString
    .trim()
    .split("\n")
    .slice(0, -1)
    .map((line) => {
      const lineLength = line.trim().split(" ").length;
      wordCount = wordCount + lineLength;
      return wordCount;
    });
  lineBreaks.unshift(0);

  const rangeNewWords = newInputString
    .replace(/\n/g, " ")
    .trim()
    .split(" ")
    .filter((w) => w); // Removing whitespace
  const rangeOldWords = oldInputString
    .replace(/\n/g, " ")
    .trim()
    .split(" ")
    .filter((w) => w); // Removing whitespace

  const lastNewWordIndex = rangeNewWords.length - 1;
  const lastOldWordIndex = rangeOldWords.length - 1;

  let startI = 0;
  while (
    rangeNewWords[startI] === rangeOldWords[startI] &&
    !!rangeNewWords[startI]
  ) {
    startI++;
  }
  let endI = 0;
  while (
    rangeNewWords[lastNewWordIndex - endI] ===
      rangeOldWords[lastOldWordIndex - endI] && // Last new word == last old word
    !!rangeNewWords[lastNewWordIndex - endI] && // Last new word exists
    lastOldWordIndex - endI > startI && // Current iteration last word is the last new word
    lastNewWordIndex - endI > startI
  ) {
    endI++;
  }

  let indexToCopy = startI;
  if (startI > lastOldWordIndex && indexToCopy > 0) indexToCopy--;

  const newChangedWords = calculateNewWordsTimes(
    rangeWords,
    rangeNewWords
  ).slice(startI, rangeNewWords.length - endI);

  const updatedRangeWords: Word[] = fullWordsArray.slice(0, startI);
  updatedRangeWords.push(...newChangedWords);
  let lastWord = fullWordsArray;
  lastWord = lastWord.slice(
    rangeOldWords.length - (endI === 0 && rangesCount === 0 ? -1 : endI)
  ); // When single speaker (rangesCount === 0) removing also last word (-1)
  updatedRangeWords.push(...lastWord);

  // Add line index to words
  for (const [lineIndex, lineBreak] of lineBreaks.entries()) {
    const nextLineBreak = lineBreaks[lineIndex + 1]
      ? lineBreaks[lineIndex + 1]
      : updatedRangeWords.length;
    for (let i = lineBreak; i < nextLineBreak; i++) {
      if (!updatedRangeWords[i]) continue;
      updatedRangeWords[i].line_ix = lineIndex;
    }
  }

  return updatedRangeWords;
};

const getRangeWordsFromMultilineStringAndSpreadTimes = (
  range: JobRange,
  newInputString: string,
  oldInputString: string,
  rangesCount: number
): Word[] => {
  const rangeWords = range.words.filter((i) => i.text !== "\n"); // Removing linebreaks;

  let wordCount = 0;
  const lineBreaks = newInputString
    .trim()
    .split("\n")
    .slice(0, -1)
    .map((line) => {
      const lineLength = line.trim().split(" ").length;
      wordCount = wordCount + lineLength;
      return wordCount;
    });
  lineBreaks.unshift(0);

  const rangeNewWords = newInputString
    .replace(/\n/g, " ")
    .trim()
    .split(" ")
    .filter((w) => w); // Removing whitespace
  const rangeOldWords = oldInputString
    .replace(/\n/g, " ")
    .trim()
    .split(" ")
    .filter((w) => w); // Removing whitespace

  const lastNewWordIndex = rangeNewWords.length - 1;
  const lastOldWordIndex = rangeOldWords.length - 1;

  let startI = 0;
  while (
    rangeNewWords[startI] === rangeOldWords[startI] &&
    !!rangeNewWords[startI]
  ) {
    startI++;
  }
  let endI = 0;
  while (
    rangeNewWords[lastNewWordIndex - endI] ===
      rangeOldWords[lastOldWordIndex - endI] && // Last new word == last old word
    !!rangeNewWords[lastNewWordIndex - endI] && // Last new word exists
    lastOldWordIndex - endI > startI && // Current iteration last word is the last new word
    lastNewWordIndex - endI > startI
  ) {
    endI++;
  }

  let indexToCopy = startI;
  if (startI > lastOldWordIndex && indexToCopy > 0) indexToCopy--;

  const updatedRangeWords = [...rangeWords];
  const lastWord = [...rangeWords].slice(
    rangeOldWords.length - (endI === 0 && rangesCount === 0 ? -1 : endI)
  ); // When single range (rangesCount === 0) removing also last word (-1)
  updatedRangeWords.push(...lastWord);

  // Add line index to words
  for (const [lineIndex, lineBreak] of lineBreaks.entries()) {
    const nextLineBreak = lineBreaks[lineIndex + 1]
      ? lineBreaks[lineIndex + 1]
      : updatedRangeWords.length;
    for (let i = lineBreak; i < nextLineBreak; i++) {
      if (!updatedRangeWords[i]) continue;
      updatedRangeWords[i].line_ix = lineIndex;
    }
  }

  const updatedWordsWithSpreadTimes = spreadRangeWordsTimes({
    words: updatedRangeWords,
    start: range.st,
    end: range.et,
  });

  return updatedWordsWithSpreadTimes;
};

const saveUserLastPosition = ({
  jobId,
  cursorPosition,
  rangeIx,
  playbackPosition,
  scrollOffsetTop,
}: {
  jobId: string;
  cursorPosition: number;
  rangeIx: number;
  playbackPosition: number;
  scrollOffsetTop: number;
}) => {
  const newLastPosition = {
    cursorPosition,
    rangeIx,
    playbackPosition,
    scrollOffsetTop,
  };
  try {
    localStorage.setItem(
      `${jobId}/editorLastPosition`,
      JSON.stringify(newLastPosition)
    );
  } catch (err) {
    logger.error(err, "saveUserLastPosition");
    clearLocalStorage({ preserveSettings: true });
    saveUserLastPosition({ ...newLastPosition, jobId });
  }
};

const getLastPosition = (jobId: string) => {
  const defaultPosition = {
    cursorPosition: 0,
    rangeIx: 0,
    playbackPosition: 0,
    scrollOffsetTop: 0,
  };
  const lastPositionRaw = localStorage.getItem(`${jobId}/editorLastPosition`);
  const lastPosition = lastPositionRaw
    ? JSON.parse(lastPositionRaw)
    : defaultPosition;

  return lastPosition;
};

const calculateNewWordsTimes = (oldWords: Word[], newWords: string[]) => {
  const words = [];
  const wordsDiff = Diff.diffArrays(
    oldWords.map((w) => w.text),
    newWords
  );

  let wordIndex = 0;
  for (let i = 0; i < wordsDiff.length; i++) {
    const diff = wordsDiff[i];

    if (diff.removed) {
      if (!_.get(wordsDiff, `[${i + 1}].added`)) {
        wordIndex = wordIndex + diff.value.length;
      } else if (diff[i + 1]) {
        wordIndex = wordIndex + (diff.value.length - diff[i + 1].value.length);
      }
      continue;
    }

    for (const word of diff.value) {
      const oldWordObj =
        wordIndex > oldWords.length - 1
          ? _.last(oldWords)
          : oldWords[wordIndex];
      if (!oldWordObj) throw new Error("NOT GOOD!");
      const wordObj = {
        // REVERT OMIT AFTER ALGO TAKES time_edit FROM RANGE (INSTEAD OF WORD)
        ..._.omit(oldWordObj, "time_edit"),
        id: generateId("w_"),
        speaker: oldWordObj.speaker,
        word: word,
        text: word,

        start: -1,
        end: -1,
        range_ix: -1,
      };

      if (!diff.removed && !diff.added) {
        (wordObj.start = oldWordObj.start),
          (wordObj.end = oldWordObj.end),
          (wordObj.range_ix = oldWordObj.range_ix);
        wordIndex++;
        words.push(wordObj);
      }

      if (diff.added) {
        (wordObj.start = oldWordObj.start),
          (wordObj.end = oldWordObj.end),
          (wordObj.range_ix = oldWordObj.range_ix);
        wordIndex++;
        words.push(wordObj);
      }
    }
  }

  return words;
};

const spreadRangeWordsTimes = ({
  words,
  start,
  end,
}: {
  words: Word[];
  start: number;
  end: number;
}) => {
  console.log("calculateAndSetRangeWordsTimes");
  const rangeDuration = end - start;
  const wordCount = words.length;

  const wordDuration = rangeDuration / wordCount;

  let wordTime = start;

  const updatedWords = _.map(words, (w) => {
    const updatedWord = {
      ...w,
      start: wordTime,
      start_time: wordTime,
      end: _.round(wordTime + wordDuration, 2),
      end_time: _.round(wordTime + wordDuration, 2),
      speaker: "speaker",
    };

    wordTime = _.round(wordTime + wordDuration, 2);

    return updatedWord;
  });

  return updatedWords;
};

const getSelectedWordsIndex = (
  plainWords: string,
  selectionStart: number,
  selectionEnd: number
): { startWordIndex: number; endWordIndex: number } => {
  const editedWords = plainWords.split(" ");
  const lengths = editedWords.map((word) => word.length);

  let start = selectionStart;

  let startWordIndex = -1;
  while (start > 0) {
    startWordIndex++;
    start = start - lengths[startWordIndex] - 1;
  }
  if (start === 0) startWordIndex++;

  let end = selectionEnd;

  let endWordIndex = -1;
  while (end > 0) {
    endWordIndex++;
    end = end - lengths[endWordIndex] - 1;
  }
  if (end === 0) endWordIndex++;

  return { startWordIndex, endWordIndex };
};

const validateOverlaps = (meeting: RoomData, ranges: number[]): number[] => {
  const words = meeting.words;
  const overlaps: number[] = [];

  for (let i = 0; i < ranges.length; i++) {
    const range = ranges[i];
    const isLastRange = i === ranges.length - 1;
    const rangeStart = words[range].start;
    const rangeEnd = isLastRange
      ? words[words.length - 1].end
      : words[ranges[i + 1] - 1].end;
    const nextRangeStart = !isLastRange ? words[ranges[i + 1]].start : null;

    const isSingleRangeOverlap = rangeStart >= rangeEnd;
    const isTwoRangeOverlap = !_.isNil(nextRangeStart)
      ? rangeEnd > nextRangeStart
      : false;

    if (isTwoRangeOverlap) {
      overlaps.push(i);
      overlaps.push(i + 1);
      continue;
    }

    if (isSingleRangeOverlap) {
      overlaps.push(i);
    }
  }

  return overlaps;
};

const generateRanges = (
  meeting: RoomData | JobData,
  format: string
): {
  ranges: number[];
  timeRanges: {
    rangeIndex: number;
    start: number;
    end: number;
    overlapping: boolean;
  }[];
  currentRange: number;
  sizes: number[];
} => {
  const ranges: number[] = [];
  const timeRanges: {
    rangeIndex: number;
    start: number;
    end: number;
    overlapping: boolean;
  }[] = [];
  const currentRange = 0;
  const sizes = [];

  if (meeting) {
    if (format === "subtitles" && !_.isNil(meeting.words[0].range_ix)) {
      let currentRange = -1;
      let rangeInstance: {
        rangeIndex?: number;
        start?: number;
        end?: number;
        overlapping: boolean;
      } | null = null;
      let timeRangeStart = meeting.words[0].start;
      let rangeSize = -1;

      for (let i = 0; i < meeting.words.length; i++) {
        const word = meeting.words[i];
        const isOneWordRange =
          meeting.words.filter((w) => w.range_ix === word.range_ix).length ===
          1;

        rangeSize++;

        if (i === meeting.words.length - 1) {
          // Last word
          const isOverlapping =
            i !== meeting.words.length - 1 &&
            meeting.words[i - 1].end < word.start;
          const lastRange = {
            rangeIndex: timeRanges.length,
            start: timeRangeStart,
            end:
              meeting.words[isOneWordRange ? i - 1 : meeting.words.length - 1]
                .end,
            overlapping: isOverlapping,
          };
          timeRanges.push(
            !_.isNil(rangeInstance)
              ? { ...rangeInstance, ...lastRange }
              : lastRange
          );
          rangeInstance = null;
          sizes.push(rangeSize);
        }

        if (
          _.isNil(word.range_ix) ||
          word.range_ix === currentRange ||
          !word.text
        ) {
          continue;
        }

        currentRange = word.range_ix;
        ranges.push(i);

        if (i !== 0) {
          // Not first
          const isOverlapping = meeting.words[i - 1].end > word.start;
          timeRanges.push({
            rangeIndex: timeRanges.length,
            start:
              isOneWordRange && i === meeting.words.length - 1
                ? word.start
                : timeRangeStart,
            end:
              meeting.words[
                isOneWordRange && i === meeting.words.length - 1
                  ? meeting.words.length - 1
                  : i - 1
              ].end,
            overlapping: isOverlapping,
            ...rangeInstance,
          });
          if (isOverlapping) {
            rangeInstance = {
              overlapping: isOverlapping,
            };
          } else {
            rangeInstance = null;
          }

          sizes.push(rangeSize);
          rangeSize = 0;
        }

        timeRangeStart = word.start;
      }
    } else {
      let currentSpeaker = "";
      let rangeSize = -1;

      for (let i = 0; i < meeting.words.length; i++) {
        const word = meeting.words[i];
        rangeSize++;

        if (i === meeting.words.length - 1) {
          sizes.push(rangeSize);
        }

        if (!word.speaker || word.speaker === currentSpeaker || !word.text) {
          continue;
        }

        if (rangeSize > 0) {
          sizes.push(rangeSize);
          rangeSize = 0;
        }
        ranges.push(i);
        currentSpeaker = word.speaker;
      }
    }
  }

  return {
    ranges,
    timeRanges,
    currentRange,
    sizes,
  };
};

const generateJobRanges = (job: JobData) => {
  const ranges: JobRange[] = [];

  if (jobTypes[job.jobType].editMode === "protocol") {
    let currentSpeaker = job.words[0].speaker;
    let currentWordRangeIndex = 0;
    let startIndex = 0;
    for (let i = 0; i < job.words.length; i++) {
      const word = job.words[i];
      const prevWord = job.words[i - 1];
      const nextWord = job.words[i + 1];

      if (!word.speaker || !word.text) {
        continue;
      }

      if (word.speaker !== currentSpeaker || !nextWord) {
        const six = startIndex;
        const eix = !nextWord ? i : i - 1;
        const range = {
          id: generateId("r_"),
          six,
          eix,
          st: job.words[startIndex].start,
          et: word.end,
          words: job.words.slice(six, eix + 1),
          type: "speaker",
          speakerName: job.words[startIndex].speaker,
          speakerId: null,
          annotations: [],
        } as SpeakerRange;

        startIndex = i;
        ranges.push(range);
        currentWordRangeIndex++;
        currentWordRangeIndex++;
      }

      currentSpeaker = word.speaker;
    }
  }
  return ranges;
};

const generateRangesByTimeIntervals = (
  words: Word[],
  interval: number
): number[] => {
  const intervalRanges = [0];
  let currentInterval = interval;
  for (let i = 0; i < words.length; i++) {
    const word = words[i];

    if (word.start <= currentInterval) {
      continue;
    }

    while (word.start >= currentInterval) {
      currentInterval = currentInterval + interval;
    }

    intervalRanges.push(i);
  }

  return intervalRanges;
};

const groupRangesByTimeIntervals = (
  words: Word[],
  interval: number
): Dictionary<Word[]> => {
  const intervalRanges = _.groupBy(words, (w) =>
    Math.floor(w.start / interval)
  );
  return intervalRanges;
};

const getWordsFromRanges = (ranges: JobRange[]) => {
  let undefindSpeakerCount = 1;
  const rangesWords = _.map(ranges, (r, i) => {
    const rangeSpeaker =
      r.speakerName || `Unidentified speaker ${undefindSpeakerCount}`;
    if (!r.speakerName) {
      undefindSpeakerCount++;
    }
    return _.map(r.words, (w) => ({
      ...w,
      range_ix: i,
      speaker: rangeSpeaker,
    }));
  });
  const words = _.flatten(rangesWords);
  return words;
};

const getSpeakerName = (word: Word, speakers: string[]): string => {
  return word.speaker;
};

const _getExportedRangeString = (
  words: Word[],
  injectSpeakerName: boolean,
  previousSpeaker: string | null
): string => {
  let currentSpeaker = previousSpeaker;
  return words
    .map((w, i) => {
      if (
        previousSpeaker &&
        injectSpeakerName &&
        w.speaker !== currentSpeaker
      ) {
        currentSpeaker = w.speaker;
        return `${i !== 0 ? "\r\n" : ""}${currentSpeaker}: ${w.text}`;
      } else {
        return w.text;
      }
    })
    .filter((w) => !!w && !!w.trim())
    .join(" ");
};

const reformatRanges = (
  roomData: RoomData,
  options: ExportOptions
): Transcript => {
  const { ranges: speakerRanges } = generateRanges(roomData, "protocol");
  const intervalRanges = generateRangesByTimeIntervals(
    roomData.words,
    options.docx.interval * 60
  );
  const ranges =
    options.docx.layout === "intervals" ? intervalRanges : speakerRanges;
  const result = ranges
    .map((rangeStart, i) => {
      let previousSpeaker = "";
      // Adding the name of the current speaker in the begining of each range
      if (options.docx.startIntervalWithSpeakerName && rangeStart > 0) {
        previousSpeaker = roomData.words[rangeStart - 1].speaker;
      }

      const rangeEnd =
        i === ranges.length - 1 ? roomData.words.length : ranges[i + 1];

      const rangeWords = roomData.words.slice(rangeStart, rangeEnd);

      const rangeMap: { speaker: string; text: string; time?: string } = {
        speaker: getSpeakerName(roomData.words[rangeStart], roomData.speakers),
        text: _getExportedRangeString(
          rangeWords,
          options.docx.layout === "intervals",
          previousSpeaker
        ),
      };
      rangeMap.time = TimeService.getTimeStringFromSecs(
        roomData.words[rangeStart].start
      );
      return rangeMap;
    })
    .filter((range) => !!range.text.trim());
  return result;
};

const getProtocolTranscriptForExport = (
  jobData: JobData,
  options: ExportOptions
): Transcript => {
  const { ranges } = jobData;
  const interval = options.docx.interval * 60;
  const unidentifiedSpeaker =
    i18n.t("unidentified_speaker") || "unidentified_speaker";

  const transcriptRanges: any[] = [];

  if (options.docx.layout === "intervals") {
    console.log("aaaaaaddd");

    const secondsOffset = options.docx.timeOffset
      ? TimeService.getTimeNumberFromString(options.docx.timeOffset)
      : 0;
    const words = _.flatten(
      _.map(ranges, (r) =>
        _.map(r.words, (w) => ({
          ...w,
          speaker: r.speakerName || unidentifiedSpeaker,
        }))
      )
    );
    const intervalRanges = groupRangesByTimeIntervals(words, interval);
    _.forEach(intervalRanges, (rangeWords, intervalIndex) => {
      let previousSpeaker = null;
      // Adding the name of the current speaker in the begining of each range
      if (!_.isEmpty(transcriptRanges)) {
        previousSpeaker = _.last(transcriptRanges).speaker;
      }

      const rangeMap: { speaker: string; text: string; time?: string } = {
        speaker: getSpeakerName(rangeWords[0], jobData.speakers),
        text: _getExportedRangeString(rangeWords, true, previousSpeaker),
      };
      rangeMap.time = TimeService.getTimeStringFromSecs(
        Number(intervalIndex) * interval + (Number(secondsOffset) || 0)
      );
      transcriptRanges.push(rangeMap);
    });

    transcriptRanges[0].text = `${transcriptRanges[0].speaker}: ${transcriptRanges[0].text}`;
  } else {
    for (const range of ranges as SpeakerRange[]) {
      transcriptRanges.push({
        speaker: range.speakerName || unidentifiedSpeaker,
        text: _.map(range.words, (w) => w.text).join(" "),
      });

      if (range.annotations) {
        for (const annotation of range.annotations) {
          transcriptRanges.push({
            speaker: i18n.t(annotation.type),
            text: annotation.text,
          });
        }
      }
    }
  }

  return transcriptRanges;
};

const exportRoomToFileByType = async (
  rooms: { id?: string; name?: string }[],
  type: string,
  options?: any,
  saveMeeting = false,
  roomData?: RoomData | JobData,
  mailInfo?: { sendToMail: boolean; email: string }
): Promise<{ success: boolean } | undefined> => {
  console.log({ type });
  if (saveMeeting && roomData) {
    await FirebaseService.saveMeeting(roomData);
  }
  if (type === "docx" && rooms[0]?.id) {
    const meetingData = await FirebaseService.getRoomData(rooms[0].id);
    if (_.isEmpty(meetingData)) return;
    const transcript = reformatRanges(meetingData, options);
    docxExportHandler(meetingData, transcript, options.docx);
    return { success: true };
  } else if (type === "locators") {
    let jobIdToExport = roomData?.roomId;
    let jobNameToExport = roomData?.name;
    if (rooms.length) {
      jobIdToExport = rooms[0].id;
      jobNameToExport = rooms[0].name;
    }
    if (!jobIdToExport || !jobNameToExport) return;

    await _handleLocatorsExport(
      jobIdToExport,
      jobNameToExport,
      roomData?.split?.parent === false,
      roomData?.status === 5
    );
    return { success: true };
  } else {
    try {
      await CloudFunctionsService.downloadJobsSubtitles(
        rooms,
        type,
        options.subtitles,
        mailInfo
      );
      return { success: true };
    } catch (err) {
      // TODO: error boundrie
      return { success: false };
    }
  }
};

const exportJobs = async ({
  jobs,
  type,
  jobData,
  mailInfo,
  options,
}: {
  jobs?: { id: string; name: string }[];
  type: string;
  jobData?: JobData;
  mailInfo?: { sendToMail: boolean; email: string };
  options?: any;
}): Promise<{ success: boolean } | undefined> => {
  let _jobData = jobData;
  if (!_jobData && jobs) {
    _jobData = await getJob(jobs[0].id, {});
  }
  if (type === "docx" && _jobData) {
    const transcript = getProtocolTranscriptForExport(_jobData, options);
    createDocxAndExport(_jobData, transcript, options.docx);
    return { success: true };
  } else if (type === "locators") {
    _jobData?.roomId &&
      (await _handleLocatorsExport(
        _jobData.roomId,
        _jobData.name,
        _jobData?.split?.parent === false,
        _jobData.status === 5
      ));
    return { success: true };
  } else if (_jobData) {
    try {
      await CloudFunctionsService.downloadJobsSubtitles(
        [{ id: jobData?.roomId, name: jobData?.name }],
        type,
        options.subtitles,
        mailInfo
      );
      return { success: true };
    } catch (err) {
      logger.error(err, "exportJobs");
      return { success: false };
    }
  }
};

const docxExportHandler = (
  roomData: RoomData | JobData,
  transcript: Transcript,
  options: {
    times: boolean;
    direction: "rtl" | "ltr";
    layout: "pages" | "minutes" | "intervals";
    interval: number;
  }
) => {
  const relevantDocx = _getRelevantDocxTemplate(options);

  // @ts-ignore
  window["PizZipUtils"].getBinaryContent(
    relevantDocx,
    (error: string, content: string) => {
      if (error) {
        throw error;
      }

      const zip = new PizZip(content);
      const doc = new Docxtemplater().loadZip(zip);
      doc.setOptions({ linebreaks: true });
      doc.setData({
        // using our docx templates from assets
        name: roomData.name,
        date: TimeService.getDateString(roomData.creationTime as Date),
        speakers: roomData.speakers,
        trans: transcript,
      });

      doc.render();

      const out = doc.getZip().generate({
        type: "blob",
        mimeType:
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      });
      saveAs(out, `${roomData.name}.docx`);
    }
  );
};

const _handleLocatorsExport = async (
  jobId: string,
  jobName: string,
  isChild?: boolean,
  isDone?: boolean
) => {
  let parentId;
  if (isChild && isDone) {
    parentId = await CloudFunctionsService.mergeJobs(jobId);
  }
  const locators = await CloudFunctionsService.getLocators(parentId || jobId);
  downloadContent(
    locators,
    "text/plain;charset=iso-8859-8",
    jobName + "_locators.txt"
  );
};

const createDocxAndExport = (
  job: JobData,
  transcript: Transcript,
  options: {
    times: boolean;
    direction: "rtl" | "ltr";
    layout: "pages" | "minutes" | "intervals";
    interval: number;
  }
) => {
  const relevantDocx = _getRelevantDocxTemplate(options);

  // @ts-ignore
  window["PizZipUtils"].getBinaryContent(
    relevantDocx,
    (error: string, content: string) => {
      if (error) {
        throw error;
      }

      const zip = new PizZip(content);
      const doc = new Docxtemplater().loadZip(zip);
      doc.setOptions({ linebreaks: true });
      doc.setData({
        // using our docx templates from assets
        name: job.name,
        date: format(job.creationTime, "dd/MM/yyyy"),
        speakers: job.speakers,
        trans: transcript,
      });

      doc.render();

      const out = doc.getZip().generate({
        type: "blob",
        mimeType:
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      });
      saveAs(out, `${job.name}.docx`);
    }
  );
};

const _getRelevantDocxTemplate = ({
  direction,
  layout,
  times,
}: {
  direction: "rtl" | "ltr";
  layout: "pages" | "minutes" | "intervals";
  times: boolean;
  interval: number;
}) => {
  const relevantTemplate = _.get(
    templates,
    `${direction}.${layout}${times ? "_times" : ""}`
  );
  return relevantTemplate;
};

const reorderWordsRangeIndex = (words: Word[]): Word[] => {
  let runningIndex = 0;
  let currentIndex = words[0].range_ix;

  return words.map((word) => {
    if (word.range_ix > currentIndex) {
      currentIndex = word.range_ix;
      runningIndex++;
    }

    return { ...word, range_ix: runningIndex };
  });
};

const getJobLangKey = (type: JobType): "input" | "output" => {
  const defaultLangKey = "output";
  const jobLangKey = jobTypes[type].lang || defaultLangKey;
  return jobLangKey;
};

const getLangDirection = (lang: string[] | string): "rtl" | "ltr" => {
  const rtlLangs = ["he-IL", "iw-IL", "ar"];
  if (_.isArray(lang)) {
    return _.some(rtlLangs, (l) => lang[0].startsWith(l)) ? "rtl" : "ltr";
  } else {
    return _.some(rtlLangs, (l) => lang.startsWith(l)) ? "rtl" : "ltr";
  }
};

const getSpeakersFromWords = (words: Word[]): string[] => {
  return _.uniqBy(words, (word) => word.speaker).map((word) => word.speaker);
};

const getSpeakersFromRanges = (ranges: SpeakerRange[]) => {
  const uniqSpeakers = _.uniq(_.map(ranges, (range) => range.speakerName));
  return _.compact(uniqSpeakers);
};

const getSplitMeetingWords = (
  words: Word[],
  wordIndex: number,
  wordCharIndex: number
): Word[] => {
  if (wordCharIndex === 0 || wordCharIndex === words[wordIndex].text.length)
    return _.clone(words);

  const updatedMeetingWords: Word[] = _.clone(words);
  const word = updatedMeetingWords[wordIndex];
  const newWord = _.clone(word);
  word.text = word.text.slice(0, wordCharIndex);
  newWord.text = newWord.text.slice(wordCharIndex, newWord.text.length);
  updatedMeetingWords.splice(wordIndex + 1, 0, newWord);

  return updatedMeetingWords;
};

const syncSubtitles = async (roomId: string) => {
  await CloudFunctionsService.fixTimestamps(roomId);
};

const alignSubtitles = async (
  roomId: string,
  validation?: RangeValidationConfig
) => {
  const snapshotOptions = { roomId, prefix: "aligner" };
  await CloudFunctionsService.wordsSnapshot(snapshotOptions);
  const response = await CloudFunctionsService.alignSubtitles(
    roomId,
    validation
  );
  return response.data;
};

const remapJob = async (jobId: string, jobLength?: number) => {
  try {
    const snapshotOptions = { roomId: jobId, prefix: "remap" };
    await CloudFunctionsService.wordsSnapshot(snapshotOptions);
    const remapEndpoint = config.v3api.url + config.v3api.remap;
    const remap = await axios.post(remapEndpoint, {
      orig_blob: `rooms/${jobId}/_orig.json`,
      trans_blob: `rooms/${jobId}/ranges.json`,
      duration: jobLength,
    });
    return remap.data;
  } catch (error) {
    logger.error("remapJob", error);
  }
};

const splitJob = async (
  roomId: string,
  splitCount: number | null,
  userId: string
) => {
  try {
    const job = await FirebaseService.getJobData(roomId);
    if (job.split) {
      throw new Error("job already splitted");
    }
    const snapshotOptions = { roomId, prefix: "split", suffix: userId };
    await CloudFunctionsService.wordsSnapshot(snapshotOptions);
    const response = await CloudFunctionsService.splitJob(roomId, splitCount);
    await FirebaseService.updateJobFields({ status: 7 }, roomId);
    return response.data;
  } catch (err) {
    logger.error(err, "splitJob");
    throw err;
  }
};

const getMergedJob = async (parentId: string, snapshot = true) => {
  try {
    const snapshotOptions = {
      roomId: parentId,
      prefix: "merge",
      suffix: FirebaseService.currentUserMetadata?.id,
    };
    if (snapshot) {
      await CloudFunctionsService.wordsSnapshot(snapshotOptions);
    }

    const mergedJob = await CloudFunctionsService.getMergedSplitJob(parentId);

    TrackingService.reportEvent("merge_job_ranges_request", {
      parent_id: parentId,
    });

    return mergedJob;
  } catch (err) {
    logger.error(err, "mergeJobs");
    throw err;
  }
};

const resetSubtitlesRanges = (words: Word[]) => {
  return _.map(words, (w) => ({ ...w, range_ix: 0 }));
};

const subtitlesExportOptions = [
  { value: "docx", text: "docx" },
  { value: "srt", text: "srt" },
  { value: "vtt", text: "vtt" },
  { value: "ass", text: "ass" },
  { value: "ssa", text: "ssa" },
  { value: "ttml", text: "ttml" },
  { value: "dfxp", text: "dfxp" },
  { value: "txt", text: "txt (Avid)" },
  { value: "locators", text: "txt (Locators)" },
];

const getWorkTime = (
  job: RoomData | JobData,
  addedWorkTime: number
): WorkTime => {
  const user = FirebaseService.currentUserMetadata;
  const workTime: WorkTime = job.workTime
    ? job.workTime
    : {
        transcribe: {},
        review: {},
      };

  if (!user) {
    return workTime;
  }

  const time: number = addedWorkTime / 1000;

  if (addedWorkTime < 0) {
    logger.error(
      `worktime - negative addedworktime (${job.roomId} - ${addedWorkTime})`
    );
  }

  const currentSession: UserEventSumup = {
    textEdit: 0,
    timeSync: 0,
    userRole: user.role,
  };
  if (job.timeSynced) {
    currentSession.timeSync = time;
  } else {
    currentSession.textEdit = time;
  }

  if (currentSession.textEdit < 0 || currentSession.timeSync < 0) {
    logger.error(
      `worktime - negative addedworktime (${job.roomId} - textEdit:${currentSession.textEdit}) - timeSync:${currentSession.timeSync}`
    );
  }

  switch (job.status) {
    case 3:
      if (user.id in workTime.transcribe) {
        workTime.transcribe[user.id].textEdit =
          workTime.transcribe[user.id].textEdit + currentSession.textEdit;
        workTime.transcribe[user.id].timeSync =
          workTime.transcribe[user.id].timeSync + currentSession.timeSync;
      } else {
        workTime.transcribe[user.id] = currentSession;
      }
      break;
    case 4:
      if (user.id in workTime.review) {
        workTime.review[user.id].textEdit =
          workTime.review[user.id].textEdit + currentSession.textEdit;
        workTime.review[user.id].timeSync =
          workTime.review[user.id].timeSync + currentSession.timeSync;
      } else {
        workTime.review[user.id] = currentSession;
      }
      break;
  }
  return workTime;
};

const totalWorkTime = (workType: WorkTime) => {
  let timeSyncSum = 0;
  let textEditTimeSum = 0;
  for (const user in workType) {
    timeSyncSum += _.get(workType, `${user}.timeSync`)
      ? _.get(workType, `${user}.timeSync`)
      : 0;
    textEditTimeSum += _.get(workType, `${user}.textEdit`)
      ? _.get(workType, `${user}.textEdit`)
      : 0;
  }
  return { timeSyncSum, textEditTimeSum };
};

const createNewSpeakerRange = ({
  words,
  st,
  et,
  speakerId = null,
  speakerName = null,
  speakerNameEdit,
  annotations = [],
}: {
  words: Word[];
  six?: number;
  eix?: number;
  st?: number;
  et?: number;
  speakerId?: null | string;
  speakerName?: null | string;
  speakerNameEdit?: boolean;
  annotations?: Annotation[];
}) => {
  const startTime = st || words[0].start;
  const endTime = et || words[words.length - 1].end;
  const newSpeakerRange = {
    id: generateId("r_"),
    six: 0,
    eix: 0,
    words: words,
    st: startTime,
    et: endTime,
    speakerName,
    speakerId,
    type: "speaker",
    annotations: annotations,
  } as SpeakerRange;
  if (speakerNameEdit) {
    newSpeakerRange.speakerNameEdit = speakerNameEdit;
  }
  return newSpeakerRange;
};

const createNewAnnotation = (rangeIndex: number, annotationIndex?: number) => {
  const newAnnotation = {
    id: FirebaseService.generateID(),
    type: "note",
    text: "",
    range_ix: rangeIndex,
    temp: true,
  } as Annotation;

  return newAnnotation;
};

const removeHiddenChars = (text: string) => {
  if (!text) {
    return "";
  }
  return text.replace(
    /([\u202B]|[\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
    ""
  );
};

export const getRangesForDocxExport = (jobData: JobData): Transcript => {
  const ranges: SpeakerRange[] = jobData.ranges as SpeakerRange[];

  const unidentifiedSpeaker =
    i18n.t("unidentified_speaker") || "unidentified_speaker";
  const transcriptRanges = [];
  for (const range of ranges) {
    transcriptRanges.push({
      speaker: range.speakerName || unidentifiedSpeaker,
      text: removeHiddenChars(_.map(range.words, (w) => w.text).join(" ")),
      type: TranscriptType.speaker,
    });

    if (range.annotations) {
      for (const annotation of range.annotations) {
        transcriptRanges.push({
          annotationType: annotation.type,
          text: removeHiddenChars(annotation.text),
          type: TranscriptType.annotation,
        });
      }
    }
  }
  return transcriptRanges;
};

const validateJobRanges = async (
  ranges: JobRange[],
  validationConfig: RangeValidationConfig
) => {
  if (_.isEmpty(ranges) || _.isEmpty(validationConfig)) {
    return ranges;
  }

  const validationTests = _.intersection(
    _.keys(validationConfig.validations),
    _.keys(rangeValidations)
  );

  const validationResults = await Promise.all(
    _.map(validationTests, (validationTestName) => {
      if (!_.has(rangeValidations, validationTestName)) return;

      const validationTest = _.get(rangeValidations, validationTestName);
      const clientValidationOptions = _.get(
        validationConfig.validations,
        validationTestName
      );

      const jobParams = {
        jobType: validationConfig.jobType,
        lang: validationConfig.lang,
        frameLength: SoundManager.frameLength,
        duration: SoundManager.jobDuration,
      };
      const validationTestOptions = _.isObject(clientValidationOptions)
        ? {
            ...clientValidationOptions,
            ...jobParams,
          }
        : false;

      return validationTest(ranges, validationTestOptions);
    })
  );

  return validationResults;
};

const getValidationConfig = async (
  clientId: string,
  jobType: PreviewFormat,
  lang: string
) => {
  if (!clientId || !jobType || !lang) return;

  const validationConfig = await FirebaseService.getValidationsConfigData(
    clientId,
    jobType,
    lang
  );

  return {
    jobType,
    lang,
    validations: validationConfig || {},
  };
};

const getJobPeaks = async (jobId: string) => {
  try {
    const peaksUrl: string = await FirebaseService.storage
      .ref(`rooms/${jobId}/peaks.json`)
      .getDownloadURL();
    const peaks = await axios.get(peaksUrl);
    if (peaks.status !== 200) {
      return null;
    }

    return peaks.data.peaks || peaks.data;
  } catch {
    return null;
  }
};

export default {
  getJob,
  getJobs,
  getUniqMergedJobs,
  updateRoomData,
  saveJob,
  getTempSpeakerName,
  getMeetingWordsFromString,
  getRangeWordsFromString,
  getRangeWordsFromMultilineString,
  getRangeWordsFromMultilineStringAndSpreadTimes,
  spreadRangeWordsTimes,
  getSelectedWordsIndex,
  validateOverlaps,
  generateRanges,
  getSpeakerName,
  reformatRanges,
  exportRoomToFileByType,
  exportJobs,
  reorderWordsRangeIndex,
  getLangDirection,
  getJobLangKey,
  getSpeakersFromWords,
  getSplitMeetingWords,
  syncSubtitles,
  alignSubtitles,
  remapJob,
  splitJob,
  getMergedJob,
  resetSubtitlesRanges,
  subtitlesExportOptions,
  getWorkTime,
  totalWorkTime,
  createNewSpeakerRange,
  createNewAnnotation,
  getWordsFromRanges,
  getRangesForDocxExport,
  saveAudit,
  saveUserLastPosition,
  getLastPosition,
  validateJobRanges,
  getValidationConfig,
  getProtocolTranscriptForExport,
  startLiveInterview,
  endLiveInterview,
  getAudioStream,
  saveWorkTime,
  getJobPeaks,
};
