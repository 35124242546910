import React, { FC, useEffect, useState, useRef } from "react";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useIdleTimer } from "react-idle-timer";
import classNames from "classnames";
import { format } from "date-fns";

import { RoomAsset, Word } from "../../models";
import { JobData } from "../../models/job";
import { jobTypes } from "../../models/jobTypes";
import { Action } from "../../models/editor";
import {
  JobRange,
  RangeValidationConfig,
  SpeakerRange,
  SubtitlesRange,
  SubtitlesTranslationRange,
} from "../../models/range";

import {
  setLoadingReason,
  clearLoadingReason,
  popIndicator,
  setErrorReason,
} from "../../store/system/actions";
import { AppState } from "../../store/rootReducer";

import Logger from "../../services/Logger";
import { FirebaseService } from "../../services/ServiceControler";
import EditorService from "../../services/EditorService";
import TimeService from "../../services/TimeService";
import TrackingService from "../../services/TrackingService";
import FeatureFlagsService from "../../services/FeatureFlagsService";
import CloudFunctionsService from "../../services/CloudFunctionsService";
import SoundManager from "../../services/SoundManager";
import { generateId } from "../../utils/generators";
import { fileSizeFormat } from "../../utils/formatters";

import JobInfoPanel from "./JobInfoPanel";
import JobActionPanel from "./JobActionPanel";

import Protocol from "./Protocol";
import Subtitles from "./Subtitles";
import SubtitlesTranslation from "./SubtitlesTranslation";

import VideoPlayer from "../../components/VideoPlayer/VideoPlayer";
import MyMediaPlayer from "../../components/MediaPlayers/MyMediaPlayer";
import SplitModalModal from "../../components/Modals/SplitModal/SplitModal";
import MessageModal from "../../components/MessageModal/MessageModal";
import ExportModal from "../../components/common/ExportModal/ExportModal";
import SelectList from "../../components/common/SelectList/SelectList";
import LoadingModal from "../../components/LoadingModal/LoadingModal";
import MediaPlayerV3 from "../../components/MediaPlayerV3/MediaPlayerV3";
import WaveformRangesV3 from "../../components/WaveformRangesV3/WaveformRangesV3";
import NoteModal from "../../components/Modals/NoteModal/NoteModal";
import ExportModalV3 from "../../components/common/ExportModalV3/ExportModalV3";
import TimeTrackModal from "../../components/Modals/TimeTrack/TimeTrack";
import { ExportConfigData } from "../ExportConfig/ExportConfigTypes";

import { createVtt } from "../../utils/createFormat";
import { focusAndSetCursor } from "../../utils/focusAndScroll";

import config from "../../config";

import "./Editor.scss";

const logger = Logger("Editor");

const Editor: FC = (): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { id } = useParams<Record<string, string>>();
  const [sessionId] = useState(generateId("s_"));

  const {
    autoSave,
    playerRewind,
    playerForward,
    playerRewindResume,
  } = useSelector((state: AppState) => state.userStore.settings);

  const loadingReason = useSelector(
    (state: AppState) => state.systemStore.loadingReason
  );

  const [job, setJob] = useState<JobData>();
  const [ranges, setRanges] = useState<JobRange[]>([]);
  const rangesRef = useRef<JobRange[]>([]);
  const [markers, setMarkers] = useState<any>([]);
  const [jobRevisions, setJobRevisions] = useState<any>([]);

  const [selectedJobRevision, setSelectedJobRevision] = useState<string | null>(
    null
  );
  const focusedRangeIndex = useRef(-1);
  const [focusedRangeWordsString, setFocusedRangeWordsString] = useState("");

  const [isChanged, setIsChanged] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [editorMode, setEditorMode] = useState("protocol");
  const [jobStreamingState, setJobStreamingState] = useState<
    JobData["streaming"]
  >();
  const [jobLang, setJobLang] = useState<string>();
  const [editorDirection, setEditorDirection] = useState<"ltr" | "rtl">("ltr");
  const [timestampsEnterMethod, setTimestampsEnterMethod] = useState<
    "word" | "player"
  >("word");
  const [isSplitView, setIsSplitView] = useState(true);
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [userActive, setUserActive] = useState(true);
  const [chosenMedia, setChosenMedia] = useState<RoomAsset>();
  const [exportConfigPresets, setExportConfigPresets] = useState<
    { id: string; config: ExportConfigData }[]
  >([]);

  const idleTimer = useIdleTimer({
    timeout: config.idleTime,
    onIdle: () => setUserActive(false),
    onActive: () => setUserActive(true),
    debounce: 500,
  });

  const [showSplitModal, setShowSplitModal] = useState(false);
  const [showExportModal, setShowExportModal] = useState(false);
  const [showTimeTrackModal, setShowTimeTrackModal] = useState(false);
  const [showExportModalV3, setShowExportModalV3] = useState(false);
  const [exportModalV3JobData, setExportModalV3JobData] = useState<JobData>();
  const [showRestoreJobModal, setShowRestoreJobModal] = useState(false);
  const [showNotesModal, setShowNotesModal] = useState<boolean>(false);
  const [exportConfig, setExportConfig] = useState<ExportConfigData>();
  const validationConfig = useRef<RangeValidationConfig>();

  const [revisionsLoader, setRevisionsLoader] = useState(false);

  const generalActions: Action[] = [
    {
      key: "notes",
      label: t("notes"),
      icon: ["fal", "sticky-note"],
      onClick: () => setShowNotesModal(true),
    },
    {
      key: "split",
      label: t("split_job"),
      icon: ["fal", "page-break"],
      onClick: () => setShowSplitModal(true),
      roles: ["super_user"],
      disabled: _.get(job, "split") ? true : false,
    },
    {
      key: "timeTrack",
      label: t("time_track"),
      icon: ["fal", "clock"],
      onClick: () => {
        setShowTimeTrackModal(true);
      },
      roles: ["super_user"],
    },
    {
      key: "direction",
      label: t(editorDirection),
      icon: ["fal", `arrow-${editorDirection === "ltr" ? "left" : "right"}`],
      onClick: () =>
        setEditorDirection((dir) => (dir === "ltr" ? "rtl" : "ltr")),
    },
    {
      key: "export",
      label: t("export"),
      icon: ["fal", "file-export"],
      onClick: async () => {
        await saveJob();
        setShowExportModal(true);
      },
      featureFlag: "exportJob",
    },
    {
      key: "exportV3",
      label: t("export_v3"),
      icon: ["fal", "file-export"],
      onClick: () => exportJobV3(),
      featureFlag: "exportConfigurationV3",
    },
    {
      key: "restoreJob",
      label: t("job_restore"),
      icon: ["fal", "history"],
      onClick: () => setShowRestoreJobModal(true),
      featureFlag: "restoreJob",
    },
    {
      key: "save",
      label: t("save"),
      icon: ["fal", "save"],
      onClick: () => saveJob("button"),
    },
  ];
  const [actions, setActions] = useState<Action[]>(generalActions);
  const [subtitlesData, setSubtitlesData] = useState("");

  const saveWorkTime = async (job: JobData | undefined) => {
    if (!job || idleTimer.isIdle()) return;
    await EditorService.saveWorkTime(job, idleTimer.getTotalActiveTime());
    idleTimer.reset();
  };

  useEffect(() => {
    const workTimeInterval = setInterval(
      () => saveWorkTime(job),
      config.workTimeSavingInterval
    );
    return () => clearInterval(workTimeInterval);
  }, [job]);

  const loggedInUser = useSelector(
    (state: AppState) => state.userStore.loggedInUser
  );

  const setLoading = (reason: string | boolean) => {
    if (!reason) {
      dispatch(clearLoadingReason());
    } else {
      dispatch(setLoadingReason(t(reason as string)));
    }
  };

  const getExportConfig = async (clientId: string) => {
    let newClientExportConfig = await FirebaseService.getClientExportConfigData(
      clientId,
      "default"
    );
    if (!newClientExportConfig) {
      newClientExportConfig = await FirebaseService.getExportConfigData();
    }
    return newClientExportConfig;
  };

  const getExportConfigPresets = async (clientId: string) => {
    let presets = await FirebaseService.getClientExportConfigPresets(clientId);
    if (!presets) {
      presets = [];
    }
    return presets;
  };

  const handleExportConfig = async (jobData: JobData) => {
    const newExportConfigPresets = await getExportConfigPresets(
      jobData.clientId
    );
    setExportConfigPresets(newExportConfigPresets);
    const newClientExportConfig = await getExportConfig(jobData.clientId);
    setExportConfig(newClientExportConfig);
  };

  const initJobData = async (jobData: JobData, isDemo = false) => {
    try {
      const jobLangKey = jobTypes[jobData.jobType].lang;
      const _jobLang = jobData.lang[jobLangKey][0];
      const languageDirection = EditorService.getLangDirection(_jobLang);

      SoundManager.jobDuration = jobData.meetingLength;

      setJobLang(_jobLang);
      setEditorDirection(languageDirection);
      setIsReadOnly(!isDemo && _.get(jobData, "process.status") !== "ready");
      setEditorMode(jobTypes[jobData.jobType].editMode);
      setJobStreamingState(jobData.streaming);
      getJobRevisions(jobData);
      setJob(jobData);
      updateRanges(jobData.ranges);
      setMarkers(jobData.markers);

      const newSubtitlesData = createVtt(jobData.ranges);
      setSubtitlesData(newSubtitlesData);

      await handleExportConfig(jobData);

      if (jobData.clientId) {
        const clientFrameRate = await FirebaseService.getClientFrameRate(
          jobData.clientId
        );
        if (clientFrameRate) {
          SoundManager.setFrameRate(clientFrameRate);
        }

        initValidation(jobData, _jobLang);
      }
    } catch (err) {
      logger.error(err);
      setErrorReason(t("indicator_error_ocurred"));
    } finally {
      dispatch(clearLoadingReason());
    }
  };

  const onPageLoad = async (jobToInit?: string) => {
    const jobId = jobToInit || id;
    const isDemo = jobId === "demo";
    const options = isDemo ? { filename: "demo.json" } : {};
    const jobData = await EditorService.getJob(jobId, options);

    if (!jobData) throw new Error("Unable to fetch job");
    await initJobData(jobData);
  };

  useEffect(() => {
    if (!loggedInUser) return;
    onPageLoad();
  }, [loggedInUser]);
  useEffect(() => {
    const keyStrokesHandler = async (e: KeyboardEvent) => {
      if (!e.ctrlKey && !e.metaKey) return;
      switch (e.code) {
        case "KeyM":
          e.preventDefault();
          await SoundManager.togglePlay((playerRewindResume || 0) * -1);
          break;
        case "KeyS":
          e.preventDefault();
          if (isReadOnly) return;
          saveJob("keyboard");
          break;
        case "KeyK":
          e.preventDefault();
          if (isReadOnly) return;
          selectCurrentWord();
          break;
        case "Period":
          e.preventDefault();
          await SoundManager.playRelative(playerForward || 5);
          break;
        case "Comma":
          e.preventDefault();
          await SoundManager.playRelative((playerRewind || 5) * -1);
          break;
      }
    };

    document.addEventListener("keydown", keyStrokesHandler, false);
    return () => document.removeEventListener("keydown", keyStrokesHandler);
  }, [job, ranges, playerRewind, playerForward, playerRewindResume]);

  // Actions
  const updateRanges = async (newRanges: JobRange[], rangeIndex?: number) => {
    const updatedRanges = [...newRanges];
    await validateJobRanges(updatedRanges, rangeIndex);

    rangesRef.current = updatedRanges;
    setRanges(updatedRanges);

    if (job && jobTypes[job.jobType].showSubtitles) {
      const newSubtitlesData = createVtt(updatedRanges);
      setSubtitlesData(newSubtitlesData);
    }
  };

  const initValidation = async (jobData: JobData, lang: string) => {
    const _validationConfig = await EditorService.getValidationConfig(
      jobData.clientId,
      jobData.jobType,
      lang
    );

    validationConfig.current = _validationConfig;
    updateRanges(jobData.ranges);
  };

  const saveUserLastPosition = () => {
    const editorContainerEl = document.getElementById("editorContainer");

    const scrollOffsetTop = _.get(editorContainerEl, "scrollTop")
      ? (_.get(editorContainerEl, "scrollTop") as number)
      : 0;
    const rangeEl = document.getElementById(
      `range-${focusedRangeIndex.current}`
    ) as HTMLTextAreaElement;
    if (rangeEl) {
      const selectionStart = rangeEl.selectionStart;
      if (
        _.isNumber(selectionStart) &&
        focusedRangeIndex.current >= 0 &&
        job?.roomId
      ) {
        EditorService.saveUserLastPosition({
          jobId: job.roomId,
          cursorPosition: selectionStart,
          rangeIx: focusedRangeIndex.current,
          playbackPosition: SoundManager.currentTime,
          scrollOffsetTop,
        });
      }
    }
  };

  const saveJob = async (saveMethod?: string) => {
    if (!job || !loggedInUser) return;

    if (FeatureFlagsService.isEnabled("saveUserLastPosition", loggedInUser)) {
      saveUserLastPosition();
    }
    const updatedJob = {
      ...job,
      ranges: rangesRef.current,
    };
    try {
      dispatch(popIndicator({ type: "info", txt: t("saving") }));
      await EditorService.saveJob(updatedJob, {
        user: loggedInUser,
        sessionId,
        saveMethod,
        addedWorkTime: idleTimer.getTotalActiveTime(),
      });

      dispatch(popIndicator({ type: "success", txt: t("save_success") }));

      TrackingService.reportEvent("job_save", {}, job);
      idleTimer.reset();
    } catch (err) {
      dispatch(popIndicator({ type: "failure", txt: t("save_fail") }));
    }
  };

  const validateJobRanges = async (ranges: JobRange[], rangeIndex?: number) => {
    if (!validationConfig.current) return;
    let rangesToValidate = ranges;

    if (!_.isNil(rangeIndex)) {
      // Validating only current edited range with prev + next ranges
      const firstRangeIndex = Math.max(rangeIndex - 1, 0);
      const lastRangesIndex = rangeIndex + 1;

      rangesToValidate = ranges.slice(firstRangeIndex, lastRangesIndex + 1);
    }

    await EditorService.validateJobRanges(
      rangesToValidate,
      validationConfig.current
    );
  };

  const approveJob = async () => {
    if (!job) return;
    try {
      await saveJob("done");
      history.push("/my-jobs");
    } catch (err) {
      logger.error("failed to done/approve meeting");
    }
  };

  const startLiveInterview = async () => {
    if (!job) return;
    dispatch(
      popIndicator({ type: "info", txt: t("job_starting_live_interview") })
    );
    try {
      const _streamingState = {
        isLive: true,
        startTime: new Date(),
      };
      await EditorService.startLiveInterview(job.roomId, _streamingState);
      setJobStreamingState(_streamingState);
      await saveJob("startLiveInterview");
      dispatch(
        popIndicator({
          type: "success",
          txt: t("job_starting_live_interview_success"),
        })
      );
    } catch (err) {
      logger.error("failed to end live interview");
      dispatch(
        popIndicator({
          type: "fail",
          txt: t("job_starting_live_interview_fail"),
        })
      );
    }
  };

  const endLiveInterview = async () => {
    if (!job || !jobStreamingState) return;
    dispatch(
      popIndicator({ type: "info", txt: t("job_ending_live_interview") })
    );
    try {
      const _streamingState = {
        isLive: false,
        startTime: jobStreamingState.startTime,
        endTime: new Date(),
      };
      setJobStreamingState(_streamingState);
      await EditorService.endLiveInterview(job.roomId, _streamingState);
      await saveJob("endLiveInterview");
      dispatch(
        popIndicator({
          type: "success",
          txt: t("job_ending_live_interview_success"),
        })
      );
    } catch (err) {
      logger.error("failed to end live interview");
      dispatch(
        popIndicator({ type: "fail", txt: t("job_ending_live_interview_fail") })
      );
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      saveJob("autosave");
    }, 1000 * 60 * 2);
    if (autoSave && userActive) {
      interval;
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [autoSave, userActive, job]);

  const handleUserIdle = () => {
    TrackingService.reportEvent("idle_start", {}, job);
  };

  const handleUserActive = () => {
    const lastIdleTime = idleTimer.getLastActiveTime()?.getTime();
    if (lastIdleTime) {
      const idleDuration = Date.now() - lastIdleTime;
      TrackingService.reportEvent(
        "idle_stop",
        {
          idle_duration: idleDuration,
        },
        job
      );
    }
  };

  useEffect(() => {
    if (userActive) {
      handleUserActive();
    } else {
      handleUserIdle();
    }
  }, [userActive]);

  const isPriceeEditAllowed = () => {
    if (!job) return;
    const { transcriber, proofer, status } = job;
    if (loggedInUser?.role === "super_user") {
      if (status === 3 && !transcriber) return true;
      if (status === 4 && !proofer) return true;
    }
    return false;
  };

  const getNoteAction = async (): Promise<Action> => {
    const hasNotes = await FirebaseService.hasNotes(id);
    const noteAction: Action = {
      key: "notes",
      label: t("notes"),
      icon: ["fal", "sticky-note"],
      onClick: () => setShowNotesModal(true),
    };
    if (hasNotes) {
      noteAction.badge = {
        hidden: false,
        position: "on",
      };
    }
    return noteAction;
  };

  const addActions = async (newActions: Action[]) => {
    const noteAction = await getNoteAction();
    //Note will always be 0 ? Or you prefer searching for it?
    generalActions[0] = noteAction;
    setActions([...newActions, ...generalActions]);
  };

  useEffect(() => {
    const _actions = actions.map((a) => {
      const timestampsEnterMethodActionLabel = t("timestamps_enter_method_1", {
        timestampsEnterMethod,
      });
      return a.key !== "timestampsEnterMethod"
        ? a
        : { ...a, label: timestampsEnterMethodActionLabel };
    });
    setActions(_actions);
  }, [timestampsEnterMethod]);

  const getJobRevisions = (jobData: JobData) => {
    setRevisionsLoader(true);
    FirebaseService.getJobRevisions(jobData, ["ranges"])
      .then((res) => {
        const formattedJobRevisions = _.map(res, (rev) => {
          const { filename, type, date, size = 0, metadata, fileType } = rev;
          const revisionDate = date && format(date, "dd/MM/yy HH:mm");

          const revisionLabelMap = {
            date: `${revisionDate} ${
              metadata && metadata.saveMethod
                ? t(`save_method_shortcut_${metadata.saveMethod}`)
                : ""
            }`,
            size: fileSizeFormat(size),
            username: _.get(metadata, "userName"),
            fileType: fileType ? t(fileType) : "",
          };

          const revisionInfo = {
            ...job,
            label: _.compact(_.values(revisionLabelMap)).join("  -  "),
            value: filename,
          };
          return revisionInfo;
        });
        setJobRevisions(formattedJobRevisions);
      })
      .catch((err) => {
        logger.error(err, "getJobRevisions");
      })
      .finally(() => {
        setRevisionsLoader(false);
      });
  };

  const restoreJob = async () => {
    setShowRestoreJobModal(false);
    dispatch(setLoadingReason(t("job_restore_loading")));
    if (selectedJobRevision) {
      const response = await FirebaseService.restoreJob(
        id,
        selectedJobRevision
      );
      if (!response.success) {
        dispatch(
          popIndicator({ type: "failure", txt: t("job_restore_failed") })
        );
      } else {
        await onPageLoad(id);
        dispatch(
          popIndicator({ type: "success", txt: t("job_restore_success") })
        );
      }
      dispatch(clearLoadingReason());
    } else {
      dispatch(popIndicator({ type: "failure", txt: t("job_restore_failed") }));
      dispatch(clearLoadingReason());
    }
  };

  const toggleTimestampsEnterMethod = () => {
    setTimestampsEnterMethod((preveEnterMethod) => {
      const _enterMethod = preveEnterMethod === "word" ? "player" : "word";
      return _enterMethod;
    });
  };
  // -Actions

  // Job
  const exportJobV3 = async () => {
    if (!job || !loggedInUser) return;
    dispatch(popIndicator({ type: "info", txt: t("saving") }));
    await saveJob();
    dispatch(popIndicator({ type: "success", txt: t("save_success") }));
    let jobData = { ...job, ranges: rangesRef.current };

    if (job.split?.parentId) {
      jobData = await getJobSplitParentData(jobData);
    }

    setExportModalV3JobData({ ...job, ranges: jobData.ranges });

    setShowExportModalV3(true);
  };

  const getJobSplitParentData = async (jobData: JobData) => {
    try {
      const parentId = _.get(jobData, "split.parentId");
      dispatch(popIndicator({ type: "info", txt: t("loading_export_data") }));
      const mergedJob = await EditorService.getMergedJob(parentId);
      return mergedJob;
    } catch (err) {
      logger.error(err, "getJobDataBeforeExportV3");
      return jobData;
    }
  };

  const splitJob = async (splitCount: "" | number) => {
    setShowSplitModal(false);
    dispatch(setLoadingReason(t("loading_splitting_job")));
    try {
      if (loggedInUser) {
        await saveJob();
        const response = await EditorService.splitJob(
          id,
          splitCount === "" ? null : splitCount,
          loggedInUser.id
        );
        if (!response.success) {
          dispatch(clearLoadingReason());
          dispatch(
            popIndicator({ type: "failure", txt: t("job_split_failed") })
          );
        } else {
          history.push(`/`);
          dispatch(
            popIndicator({ type: "sucsess", txt: t("job_split_success") })
          );
        }
        idleTimer.reset();
      }
    } catch (err: any) {
      if (err.message === "job already splitted") {
        dispatch(
          popIndicator({ type: "warning", txt: t("job_already_splitted") })
        );
      } else {
        logger.error(err, "splitJob");
        dispatch(popIndicator({ type: "failure", txt: t("job_split_failed") }));
      }
    } finally {
      dispatch(clearLoadingReason());
    }
  };

  const chooseMedia = (media: RoomAsset) => {
    setChosenMedia(media);
    if (!media.frameRate) return;
    SoundManager.setFrameRate(media.frameRate);
  };

  // -Job

  // Words
  const updateWordTime = (
    wordIndex: number,
    timeString: string,
    position: string,
    method?: "button" | "text"
  ) => {
    if (!job) return;
    const updatedMeetingWords: Word[] = job.words;

    const timeInSecs = TimeService.getTimeNumberFromString(timeString);
    const oldTime = _.get(updatedMeetingWords, `${wordIndex}.${position}`);
    const timeDiff = !_.isNaN(timeInSecs - oldTime)
      ? Math.round((timeInSecs - oldTime) * 1000)
      : null;

    updatedMeetingWords[wordIndex] = {
      ...updatedMeetingWords[wordIndex],
      [position]: timeInSecs,
    };

    setIsChanged(true);
    setJob({ ...job, words: updatedMeetingWords });

    TrackingService.reportEvent(
      "word_time_change",
      {
        time_diff: timeDiff,
        time_position: position,
        change_method: method,
      },
      job
    );
  };

  const selectCurrentWord = () => {
    TrackingService.reportEvent("select_playing_word", {}, job);

    const currentWord: {
      word: Word;
      start_index: number;
      end_index: number;
    } | null = getCurrentWord();
    if (!currentWord) return;

    const range = document.getElementById(
      `range-${currentWord.word.range_ix}`
    ) as HTMLTextAreaElement;
    range?.focus();
    range?.setSelectionRange(currentWord.start_index, currentWord.end_index);
  };
  // - Words

  // Ranges
  const setFocusedRangeIndex = (rangeIx: number) => {
    if (rangeIx !== focusedRangeIndex.current) {
      focusedRangeIndex.current = rangeIx;
    }
  };

  const updateRangeTimes = ({
    rangeIndex,
    start,
    end,
    method,
  }: {
    rangeIndex: number;
    start?: number;
    end?: number;
    method: "button" | "text" | "waveform";
  }) => {
    const updatedRanges = [...rangesRef.current];

    if (start) {
      updatedRanges[rangeIndex].st = start;
      updatedRanges[rangeIndex].words[0].start = start;
      updatedRanges[rangeIndex].words[0].start_time = start;
      updatedRanges[rangeIndex].words[0].time_edit = true;
    }

    if (end) {
      const lastWordIndex = updatedRanges[rangeIndex].words.length - 1;
      updatedRanges[rangeIndex].et = end;
      updatedRanges[rangeIndex].words[lastWordIndex].end = end;
      updatedRanges[rangeIndex].words[lastWordIndex].end_time = end;
      updatedRanges[rangeIndex].words[lastWordIndex].time_edit = true;
    }

    if (job && jobTypes[job.jobType].showSubtitles) {
      const newSubtitlesData = createVtt(updatedRanges);
      setSubtitlesData(newSubtitlesData);
    }

    updatedRanges[rangeIndex].time_edit = true;

    if (FeatureFlagsService.isEnabled("spreadRangeWordTimes", loggedInUser)) {
      updatedRanges[rangeIndex].words = EditorService.spreadRangeWordsTimes({
        words: updatedRanges[rangeIndex].words,
        start: updatedRanges[rangeIndex].st,
        end: updatedRanges[rangeIndex].et,
      });
    }

    updateRanges(updatedRanges, rangeIndex);
  };

  const getCurrentWord = () => {
    if (!job || !ranges) return null;
    let _currentTime: number;
    setCurrentTime((ct) => {
      _currentTime = ct;
      return ct;
    });

    const currentWord = _.find(
      job.words,
      (w, i) =>
        (w.start < _currentTime && w.end > _currentTime) ||
        (w.end < _currentTime && job.words[i + 1].start > _currentTime)
    );

    if (!currentWord) return null;

    let wordStartIndex = -1;
    let wordEndIndex = 0;
    for (const rangeWord of job.words.filter(
      (w) => w.range_ix === currentWord.range_ix
    )) {
      wordStartIndex++;
      if (currentWord === rangeWord) {
        wordEndIndex = wordStartIndex + rangeWord.text.length;
        break;
      }
      wordStartIndex = wordStartIndex + rangeWord.text.length;
    }

    return {
      word: currentWord,
      start_index: wordStartIndex,
      end_index: wordEndIndex,
    };
  };
  // - Ranges

  return loadingReason || !loggedInUser || !job || !ranges ? (
    <LoadingModal loadingReason={loadingReason || ""} />
  ) : (
    <div
      className={classNames(
        "Editor main-container",
        editorDirection,
        jobTypes[job.jobType].editMode
      )}
    >
      <div className="headerSection">
        <JobInfoPanel job={job} user={loggedInUser} />
        <JobActionPanel
          editorDirection={editorDirection}
          actions={actions}
          user={loggedInUser}
        >
          <div className={classNames("approvalPanel", editorDirection)}>
            <div className="jobDeadline jobInfoItem">
              {job.deadline
                ? TimeService.getDeadlineVerbalString(job.deadline, t)
                : "0"}
            </div>
            {jobTypes[job.jobType].stream && !jobStreamingState?.endTime ? (
              <>
                {!jobStreamingState?.isLive && !jobStreamingState?.endTime && (
                  <div
                    className="startLiveInterview"
                    onClick={startLiveInterview}
                  >
                    {t("start_live_interview")}
                  </div>
                )}
                {jobStreamingState?.isLive && (
                  <div className="endLiveInterview" onClick={endLiveInterview}>
                    {t("end_live_interview")}
                  </div>
                )}
              </>
            ) : (
              <div className="jobApproval jobInfoItem" onClick={approveJob}>
                {t("done")}
              </div>
            )}
          </div>
        </JobActionPanel>
      </div>
      {editorMode === "protocol" && (
        <Protocol
          job={job}
          setJob={setJob}
          saveJob={saveJob}
          ranges={rangesRef.current as SpeakerRange[]}
          updateRanges={updateRanges}
          focusedRangeIndex={focusedRangeIndex.current}
          setFocusedRangeIndex={setFocusedRangeIndex}
          setFocusedRangeWordsString={setFocusedRangeWordsString}
          addActions={addActions}
          currentTime={currentTime}
          setIsChanged={setIsChanged}
          updateRangeTimes={updateRangeTimes}
          editorDirection={editorDirection}
          setIsLoading={(loading: boolean | string) => setLoading(loading)}
          isDisabled={isReadOnly}
          toast={(msg, type) => dispatch(popIndicator({ type, txt: msg }))}
          allowTimeEdit={FeatureFlagsService.isEnabled("allowProtocolTimeEdit")}
        />
      )}
      {editorMode === "subtitles" && (
        <Subtitles
          job={job}
          saveJob={saveJob}
          ranges={rangesRef.current as SubtitlesRange[]}
          updateRanges={updateRanges}
          focusedRangeIndex={focusedRangeIndex.current}
          setFocusedRangeIndex={setFocusedRangeIndex}
          addActions={addActions}
          currentTime={currentTime}
          setIsChanged={setIsChanged}
          timestampsEnterMethod={timestampsEnterMethod}
          toggleTimestampsEnterMethod={toggleTimestampsEnterMethod}
          updateRangeTimes={updateRangeTimes}
          rangeValidationConfig={validationConfig.current}
          editorDirection={editorDirection}
          isDisabled={isReadOnly}
          setIsLoading={(loading: boolean | string) => setLoading(loading)}
          toast={(msg, type) => dispatch(popIndicator({ type, txt: msg }))}
        />
      )}
      {editorMode === "subtitles-translation" && (
        <SubtitlesTranslation
          job={job}
          ranges={rangesRef.current as SubtitlesTranslationRange[]}
          updateRanges={updateRanges}
          focusedRangeIndex={focusedRangeIndex.current}
          setFocusedRangeIndex={setFocusedRangeIndex}
          currentTime={currentTime}
          editorDirection={editorDirection}
          addActions={addActions}
        />
      )}
      <div className="playerSection">
        {/* REMOVE FEATURE FLAG */}
        {!FeatureFlagsService.isEnabled("mediaPlayerV3", loggedInUser) && (
          <>
            {job.assets.length ? (
              <div className="audioList">
                <div className="audioFilesTitle">
                  <h4>{t("audio_files")}</h4>
                </div>
                <div className="audios">
                  {job.assets.map((audio, i) => (
                    <div
                      className={classNames("audio", {
                        current: chosenMedia?.src === audio.src,
                      })}
                      key={i}
                      onClick={() => chooseMedia(audio)}
                    >
                      {audio.name}
                    </div>
                  ))}
                </div>
              </div>
            ) : null}

            {!_.isEmpty(job.assets) && job.assets[0].type === "video" ? (
              <VideoPlayer
                videoSources={job.assets}
                currentCaption={""} // getCurrentCaptionWords()
                isFloating={!isSplitView}
                direction={editorDirection}
              />
            ) : null}
            <MyMediaPlayer
              showMediaSourceManagement={false}
              offsets={job.offsets}
              mediaSources={job.assets}
              sendCurrTime={setCurrentTime}
              isDraggable={!isSplitView}
              isFloating={!isSplitView}
              isSplitView={isSplitView}
              toggleIsFloating={() => setIsSplitView(!isSplitView)}
              allowSplit={true}
              chosenMedia={chosenMedia}
              setChosenMedia={setChosenMedia}
            />
          </>
        )}
      </div>
      <SplitModalModal
        jobLength={job.meetingLength}
        splitJob={splitJob}
        showModal={showSplitModal}
        closeModal={() => setShowSplitModal(false)}
      />

      {FeatureFlagsService.isEnabled("exportConfigurationV3", loggedInUser) &&
        showExportModalV3 &&
        exportModalV3JobData &&
        exportConfig && (
          <ExportModalV3
            isOpen={showExportModalV3}
            setOpen={setShowExportModalV3}
            jobsData={[exportModalV3JobData]}
            //Currently Needs To Handle Both in Case Client Does Not Have Any Presets
            exportConfig={exportConfig}
            presets={exportConfigPresets}
          />
        )}

      <ExportModal
        isOpen={showExportModal}
        setOpen={setShowExportModal}
        jobData={{ ...job, ranges: rangesRef.current }}
        frameRate={SoundManager.frameRate}
      />
      <TimeTrackModal
        showModal={showTimeTrackModal}
        closeModal={() => setShowTimeTrackModal(false)}
        workTime={job.workTime ? job.workTime : { transcribe: 0, review: 0 }}
      />
      <MessageModal
        className="restoreWordsModal"
        title={t("job_restore")}
        body={
          <div className="body">
            <div className="label">{t("select_revision")}</div>
            <div className="revisionSelect">
              <SelectList
                isLoading={revisionsLoader}
                options={jobRevisions}
                onChange={(file) =>
                  setSelectedJobRevision(_.isArray(file) ? file[0] : file)
                }
                className="styledInput"
              />
            </div>
          </div>
        }
        showModal={showRestoreJobModal}
        approve={{ text: t("approve"), action: () => restoreJob() }}
        cancel={{
          text: t("cancel"),
          action: () => {
            setSelectedJobRevision(null);
            setShowRestoreJobModal(false);
          },
        }}
        isApproveDisabled={!selectedJobRevision}
      />
      {showNotesModal && (
        <NoteModal
          jobId={job.roomId}
          notes={job.notes || ""}
          showModal={showNotesModal}
          closeModal={() => setShowNotesModal(false)}
          onSubmit={async (note) => {
            await FirebaseService.updateNotes(note || "", job.roomId);
            job.notes = note;
            setShowNotesModal(false);
          }}
        />
      )}
      {/* REMOVE FEATURE FLAG */}
      {FeatureFlagsService.isEnabled("mediaPlayerV3", loggedInUser) &&
        !_.isEmpty(job.assets) && (
          <MediaPlayerV3
            loggedInUser={loggedInUser}
            initialPlaybackPosition={
              FeatureFlagsService.isEnabled(
                "saveUserLastPosition",
                loggedInUser
              )
                ? EditorService.getLastPosition(job.roomId)
                    .initialPlaybackPosition
                : 0
            }
            currentCaption={""} //getCurrentCaptionWords()
            sendCurrTime={setCurrentTime}
            direction={editorDirection}
            mediaSources={job.assets}
            hasVideo={chosenMedia?.type === "video"}
            chosenMedia={chosenMedia}
            setChosenMedia={chooseMedia}
            offsets={job.offsets}
            subtitlesData={subtitlesData}
            displayText={jobTypes[job.jobType].showSubtitles}
            isStreaming={jobTypes[job.jobType].stream && job.streaming?.isLive}
            jobId={job.roomId}
          >
            <WaveformRangesV3
              job={job}
              markers={markers}
              ranges={jobTypes[job.jobType].showSubtitles ? ranges : []}
              updateRangeTimes={updateRangeTimes}
              jobId={job.roomId}
              initialZoomValue={jobTypes[job.jobType].showSubtitles ? 50 : 0}
              showZoomOption={!!jobTypes[job.jobType].showSubtitles}
              focusRange={focusAndSetCursor}
              duration={job.meetingLength}
            />
          </MediaPlayerV3>
        )}
    </div>
  );
};

export default Editor;
