import React, { FC, useEffect, useState, useRef } from "react";
import _ from "lodash";
import i18n from "../../i18n";
import { format } from "date-fns";

import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Dialog from "@material-ui/core/Dialog";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { AppState } from "../../store/rootReducer";
import {
  clearMeetings,
  setMeetings,
  updateMeeting,
  removeMeeting,
} from "../../store/meeting/actions";
import MeetingActions from "./MeetingActions";
import { getProofers, getTranscribers } from "../../store/user/actions";
import {
  setLoadingReason,
  resetSorting,
  clearLoadingReason,
  popIndicator,
  setErrorReason,
} from "../../store/system/actions";
import {
  MeetingPreviewData,
  MeetingTableItem,
  ElasticJob,
} from "../../models/meeting";
import { MinimalProofer, MinimalTranscriber } from "../../models/user";
import { DocumentReference, DocumentData } from "@firebase/firestore-types";

import { getLanguageDirection, getLanguages } from "../../utils/locales";
import NotificationService from "../../services/NotificationService";
import TimeService from "../../services/TimeService";
import MeetingService from "../../services/MeetingService";
import { FirebaseService } from "../../services/ServiceControler";
import * as SettingService from "../../services/SettingService";
import FeatureFlagsService from "../../services/FeatureFlagsService";
import ExportService from "../../services/ExportService";
import EditorService from "../../services/EditorService";
import Logger from "../../services/Logger";

import PageHeader from "../../components/PageHeader/PageHeader";
import LoadingModal from "../../components/LoadingModal/LoadingModal";
import ErrorModal from "../../components/ErrorModal/ErrorModal";
import EmptyPageComp from "../../components/EmptyPageComp/EmptyPageComp";
import MeetingsTable from "../../components/MeetingsTable/MeetingsTable";
import TranscribersList from "../../components/MeetingPreview/TranscribersList";
import NoteModal from "../../components/Modals/NoteModal/NoteModal";
import CircleProgress from "../../components/common/CircleProgress/CircleProgress";
import EditModal from "../../components/Modals/EditModal/EditModal";
import ApproveModal from "../../components/MeetingPreview/ApproveModal";
import JobAuditModal from "../../components/common/JobAuditModal/JobAuditModal";
import SplitModalModal from "../../components/Modals/SplitModal/SplitModal";

import ContextMenu from "../ArchivePage/ContextMenu";
import { getJobsUrl } from "../../utils/url";
import { JobsPageExcelFields } from "../../constants/excelFields";

import "./MeetingsPages.scss";

const logger = Logger("ClientMeetingsPage");
type ApprovalType =
  | "to_proof"
  | "to_send"
  | "return_to_available_meetings"
  | "return_to_transcriber"
  | "unassign_transcriber_from_Meeting"
  | "unassign_proofer_from_meeting";
class Action {
  type: string;
  meeting?: MeetingPreviewData;
  meetingId?: string;
  transcriberId?: string;
  onSuccess: () => void;
}

const MeetingsPage: FC = (): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const loggedInUser = useSelector(
    (state: AppState) => state.userStore.loggedInUser
  );
  const loadingReason = useSelector(
    (state: AppState) => state.systemStore.loadingReason
  );
  const settings = useSelector((state: AppState) => state.userStore.settings);
  const errorReason = useSelector(
    (state: AppState) => state.systemStore.errorReason
  );
  const meetings = useSelector(
    (state: AppState) => state.meetingStore.meetings
  );
  const transcribers = useSelector(
    (state: AppState) => state.userStore.transcribers
  );
  const proofers = useSelector((state: AppState) => state.userStore.proofers);

  const [meetingsTableData, setMeetingsTableData] = useState<
    MeetingTableItem[]
  >([]);
  const sorting = useSelector((state: AppState) => state.systemStore.sorting);
  const [searchBy, setSearchBy] = useState<string>("");
  const [selectedButton, setSelectedButton] = useState("");
  const [
    selectedMeeting,
    setSelectedMeeting,
  ] = useState<MeetingPreviewData | null>(null);

  const [showModal, setShowModal] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isNoteModalOpen, setIsNoteModalOpen] = useState(false);
  const [isActionLoading, setIsActionLoading] = useState(false);
  const [jobModalOpen, setJobModalOpen] = useState(false);
  const [meetingId, setMeetingId] = useState<string>();
  const [showSplitModal, setShowSplitModal] = useState(false);

  const onSuccess = () => {
    setIsActionLoading(false);
    closeModal();
  };

  useEffect(() => {
    return () => {
      dispatch(clearMeetings());
    };
  }, []);
  useEffect(() => {
    if (sorting.type !== "meetings") dispatch(resetSorting("meetings"));
    if (loggedInUser) {
      if (
        ["transcriber+proofer", "super_user", "proofer"].includes(
          loggedInUser.role
        )
      ) {
        dispatch(getTranscribers());
        if ("super_user" === loggedInUser.role) {
          dispatch(getProofers());
        }
      }
      loadAvailableMeetings();
    }
    return () => {
      dispatch(clearMeetings());
    };
  }, [loggedInUser]);

  useEffect(() => {
    if (loggedInUser) {
      const formatMeeting = async () => {
        const formatMeetings = await getFormatMeetingsTable(meetings);
        if (formatMeetings) {
          setMeetingsTableData(formatMeetings);
        }
      };
      formatMeeting();
    }
  }, [meetings]);

  const loadAvailableMeetings = async () => {
    try {
      if (loggedInUser) {
        dispatch(setLoadingReason(t("loading_available_meetings")));
        const meetings = await FirebaseService.getAvailableJobs(
          loggedInUser,
          true,
          FeatureFlagsService.isEnabled("mergeTrackingTableToAvailable")
        );
        if (meetings) {
          dispatch(setMeetings(meetings));
          const formatMeetings = await getFormatMeetingsTable(meetings);
          if (formatMeetings) {
            setMeetingsTableData(formatMeetings);
          }
        }
        dispatch(clearLoadingReason());
      }
    } catch (err) {
      console.log(err);
      dispatch(setErrorReason(t("indicator_error_ocurred")));
      dispatch(clearLoadingReason());
    }
  };

  const orderTable = (property: string, descOrAsc: "desc" | "asc") => {
    SettingService.setSettings("orderBy", property, dispatch);
    SettingService.setSettings("order", descOrAsc, dispatch);
  };

  const editJob = (newJob: MeetingPreviewData | ElasticJob) => {
    dispatch(updateMeeting(newJob));
  };

  const splitJob = async (splitCount: "" | number) => {
    if (!loggedInUser || !selectedMeeting) return;
    try {
      setShowSplitModal(false);
      dispatch(popIndicator({ type: "info", txt: t("loading_splitting_job") }));
      const splitJobRequest = await EditorService.splitJob(
        selectedMeeting.id,
        splitCount === "" ? null : splitCount,
        loggedInUser.id
      );
      if (!splitJobRequest.success) {
        dispatch(popIndicator({ type: "failure", txt: t("job_split_failed") }));
      } else {
        dispatch(
          popIndicator({ type: "success", txt: t("job_split_success") })
        );
        dispatch(removeMeeting(selectedMeeting.id));
      }
    } catch (err: any) {
      if (err.message === "job already splitted") {
        dispatch(
          popIndicator({ type: "warning", txt: t("job_already_splitted") })
        );
      } else {
        logger.error(err, "splitJob");
        dispatch(popIndicator({ type: "failure", txt: t("job_split_failed") }));
      }
    }
  };

  const getTextualStatus = (
    status: number,
    assignedTranscriber: DocumentReference<DocumentData> | null,
    assignedProofer: DocumentReference<DocumentData> | null | undefined
  ) => {
    if (status === 3) {
      return assignedTranscriber ? "at_work" : "open";
    } else if (status === 4) {
      return assignedProofer ? "reviewing" : "pending";
    } else {
      return "processing";
    }
  };
  const getFormatMeetingsTable = async (meetings: MeetingPreviewData[]) => {
    try {
      const formatMeetings = await Promise.all(
        meetings.map(async (meeting) => {
          const {
            id,
            name,
            deadline,
            clientDeadline,
            clientName,
            meetingLength,
            processProgressLastUpdate,
            speakers,
            assignedTranscriber,
            lang,
            editProgress,
            jobType: previewFormat,
            assignedProofer,
            price,
            translation,
            prooferPrice,
            creationTime,
            status,
            process,
            workTime,
            hasNotes,
            v3id,
          } = meeting;
          const transcriberId = assignedTranscriber?.id;
          const transName =
            transcriberId && transcribers
              ? MeetingService.getTranscriberFromTranscribers(
                  transcriberId,
                  transcribers,
                  "username"
                )
              : "";
          let langText = "-";
          const input = t(lang.input[0]);
          const output = t(lang.output[0]);
          if (lang) {
            langText =
              previewFormat === "subtitles" ? input : `${input} - ${output}`;
          }

          const prooferId = assignedProofer?.id;
          const prooferName =
            prooferId && proofers
              ? MeetingService.getMember(prooferId, proofers, "username")
              : "";
          const statusText = getTextualStatus(
            status,
            assignedTranscriber,
            assignedProofer
          );
          const menuItems = [
            {
              onClick: () => {
                setSelectedMeeting(meeting);
                setIsEditModalOpen(true);
              },
              text: t("edit"),
            },
            {
              onClick: () => {
                setSelectedMeeting(meeting);
                setIsNoteModalOpen(true);
              },
              text: t("notes"),
            },
            {
              onClick: () => {
                setMeetingId(meeting.id);
                setJobModalOpen(true);
              },
              text: t("audit"),
            },
            {
              onClick: () => {
                setSelectedMeeting(meeting);
                setShowSplitModal(true);
              },
              text: t("split_job"),
            },
          ];
          if (transcriberId) {
            menuItems.push({
              onClick: () => {
                history.push(`/transcriber/${transcriberId}`);
              },
              text: t("transcriber"),
            });
          }
          if (prooferId) {
            menuItems.push({
              onClick: () => {
                history.push(`/transcriber/${prooferId}`);
              },
              text: t("proofer"),
            });
          }
          const transcribingTotalTime = EditorService.totalWorkTime(
            workTime?.transcribe
          );
          const reviewingTotalTime = EditorService.totalWorkTime(
            workTime?.review
          );

          const meetingObj = {
            translation,
            speakers: speakers ? speakers.length : 0,
            lang: langText,
            reviewTimeSync: reviewingTotalTime.timeSyncSum,
            reviewTextEditTime: reviewingTotalTime.textEditTimeSum,
            transcribeTimeSync: transcribingTotalTime.timeSyncSum,
            transcribeTextEditTime: transcribingTotalTime.textEditTimeSum,
            processProgress:
              status === 1 ? Number(process.progress) : editProgress,
            processProgressLastUpdate,
            deadline: deadline,
            clientDeadline: clientDeadline,
            uploadDate: creationTime,
            creationTime: creationTime
              ? format(new Date(creationTime), "MM/dd/yyyy")
              : " - ",
            meetingLength: meetingLength
              ? TimeService.getTimeStringFromSecs(meetingLength)
              : "-",
            fileLength: deadline
              ? TimeService.getDeadlineString(deadline)
              : "0",
            job: statusText,
            jobStatus: t(statusText),
            prooferPrice: prooferPrice ? prooferPrice : 0,
            assignedProofer: prooferName ? prooferName : t("no_proofer"),
            price: price ? price : 0,
            assignedTranscriber: transName ? transName : t("no_transcriber"),
            output: previewFormat === "subtitles" ? input : output,
            input: input,
            status,
            previewFormat: t(previewFormat),
            clientName: clientName ? clientName : t("no_client"),
            name,
            id,
            v3id,
            contextMenu: (
              <ContextMenu
                showBadge={!hasNotes}
                menuItems={menuItems}
              ></ContextMenu>
            ),
            actions: (
              <MeetingActions
                setSelectedMeeting={setSelectedMeeting}
                setSelectedButton={setSelectedButton}
                setShowModal={setShowModal}
                meeting={meeting}
              />
            ),
          };
          return meetingObj;
        })
      );
      return formatMeetings;
    } catch (err) {
      logger.log(err, "getFormatMeetingsTable");
    }
  };

  const onSearchBy = (searchBy: string) => {
    setSearchBy(searchBy);
  };
  const searchAndSorting = {
    onSearchInput: onSearchBy,
    currSearchBy: searchBy,
  };

  const assignTranscriberToMeeting = async (
    meeting: MeetingPreviewData,
    transcriber: MinimalTranscriber,
    onSuccess: () => void,
    recordsRemain = false
  ) => {
    if (!loggedInUser) return;
    try {
      const method =
        transcriber.id === loggedInUser?.id ? "claimed" : "assigned";
      if (loggedInUser) setIsActionLoading(true);
      await FirebaseService.assignTranscriber(
        meeting.id,
        transcriber.id,
        method
      );
      if (method === "claimed" && !recordsRemain)
        dispatch(removeMeeting(meeting.id));
      else {
        const updatedMeeting = { ...meeting };
        updatedMeeting.assignedTranscriber = FirebaseService.getTranscriberRef(
          transcriber.id
        );
        updatedMeeting.transcriberId = transcriber.id;

        if (method !== "claimed") {
          updatedMeeting.assignedMethod = "assigned";
        } else {
          updatedMeeting.assignedMethod = "claimed";
          updatedMeeting.isApproved = true;
        }
        dispatch(updateMeeting(updatedMeeting));
        const recipient = FirebaseService.getDocRef("users-v2", transcriber.id);
        const info = {
          roomName: meeting.name,
          sentBy: {
            name: loggedInUser.username,
            id: loggedInUser.id,
          },
        };
        NotificationService.dispatchNotification(
          "gotAssigned",
          recipient,
          info
        );
      }
      onSuccess();
      dispatch(
        popIndicator({ type: "success", txt: t("indicator_assign_success") })
      );
    } catch (err) {
      console.log(err);
      onSuccess();
      dispatch(
        popIndicator({ type: "failure", txt: t("indicator_assign_failure") })
      );
    } finally {
      setIsActionLoading(false);
    }
  };

  const assignProoferToMeeting = async (
    meeting: MeetingPreviewData,
    proofer: MinimalProofer,
    onSuccess: () => void,
    recordsRemain = false
  ) => {
    if (!loggedInUser) return;
    try {
      const method = proofer.id === loggedInUser?.id ? "claimed" : "assigned";
      if (loggedInUser) setIsActionLoading(true);
      await FirebaseService.assignProofer(meeting.id, proofer.id);
      if (!recordsRemain) dispatch(removeMeeting(meeting.id));
      else {
        const updatedMeeting = { ...meeting };
        updatedMeeting.assignedProofer = FirebaseService.getTranscriberRef(
          proofer.id
        );
        updatedMeeting.reviewerId = proofer.id;
        if (method !== "claimed") {
          updatedMeeting.assignedMethod = "assigned";
        } else {
          updatedMeeting.assignedMethod = "claimed";
          updatedMeeting.isApproved = true;
        }
        dispatch(updateMeeting(updatedMeeting));
        const recipient = FirebaseService.getDocRef("users-v2", proofer.id);
        const info = {
          roomName: meeting.name,
          sentBy: {
            name: loggedInUser.username,
            id: loggedInUser.id,
          },
        };
        NotificationService.dispatchNotification(
          "gotAssigned",
          recipient,
          info
        );
      }
      onSuccess();
      dispatch(
        popIndicator({ type: "success", txt: t("indicator_assign_success") })
      );
    } catch (err) {
      console.log(err);
      onSuccess();
      dispatch(
        popIndicator({ type: "failure", txt: t("indicator_assign_failure") })
      );
    } finally {
      setIsActionLoading(false);
    }
  };

  const returnToTranscriber = async (
    meeting: MeetingPreviewData,
    onSuccess: () => void
  ) => {
    try {
      if (!loggedInUser) return;
      setIsActionLoading(true);
      await FirebaseService.returnToTranscriber(meeting);
      onSuccess();
      const updatedMeeting = {
        ...meeting,
        status: 3,
      };
      dispatch(updateMeeting(updatedMeeting));
      dispatch(
        popIndicator({ type: "success", txt: t("indicator_meeting_returned") })
      );
    } catch (err) {
      logger.log(err, "returnToTranscriber");
      onSuccess();
      dispatch(
        popIndicator({ type: "failure", txt: t("indicator_error_ocurred") })
      );
    }
  };
  const unAssignProoferFromMeeting = async (
    meeting: MeetingPreviewData,
    onSuccess: () => void,
    recordsRemain = false
  ) => {
    try {
      if (!loggedInUser) return;
      setIsActionLoading(true);
      await FirebaseService.unAssignProofer(meeting.id);
      onSuccess();
      if (!recordsRemain) {
        dispatch(removeMeeting(meeting.id));
        setMeetingsTableData(
          meetingsTableData.filter((meeting) => meeting.id !== meeting.id)
        );
      } else {
        const updatedMeeting = {
          ...meeting,
          assignedProofer: null,
          reviewerId: null,
        };
        dispatch(updateMeeting(updatedMeeting));
      }
      dispatch(
        popIndicator({ type: "success", txt: t("indicator_meeting_returned") })
      );
    } catch (err) {
      logger.log(err, "unAssignProoferFromMeeting");
      onSuccess();
      dispatch(
        popIndicator({ type: "failure", txt: t("indicator_error_ocurred") })
      );
    }
  };

  const unAssignTranscriberFromMeeting = async (
    meeting: MeetingPreviewData,
    onSuccess: () => void,
    recordsRemain = false
  ) => {
    try {
      if (meeting.id) {
        setIsActionLoading(true);
        loggedInUser && (await FirebaseService.unassignTranscriber(meeting.id));
        if (!recordsRemain) {
          dispatch(removeMeeting(meeting.id));
          setMeetingsTableData(
            meetingsTableData.filter(
              (tableMeeting) => tableMeeting.id !== meeting.id
            )
          );
        } else {
          const updatedMeeting = {
            ...meeting,
            assignedTranscriber: null,
            assignedMethod: null,
            transcriberId: null,
            assignedB: null,
            isApproved: false,
          };
          dispatch(updateMeeting(updatedMeeting));
        }
        dispatch(
          popIndicator({
            type: "success",
            txt: t("indicator_request_declined"),
          })
        );
        onSuccess();
      }
    } catch (err) {
      logger.log(err, "unAssignTranscriberFromMeeting");
      onSuccess(); //todo check this
      dispatch(
        popIndicator({ type: "failure", txt: t("indicator_error_ocurred") })
      );
    }
  };
  const approvalType = {
    to_proof: "send_to_proof",
    to_send: "approve_meeting",
    return_to_available_meetings: "release_meeting",
    return_to_transcriber: "return_to_transcriber",
    unassign_transcriber_from_Meeting: "release_from_transcriber",
    unassign_proofer_from_meeting: "release_from_proofer",
  };

  const goToMeeting = (
    meeting: { [key: string]: any },
    event: React.MouseEvent
  ) => {
    const job = meetings.find((job) => job.id === meeting.id);
    if (
      meeting.status >= 3 &&
      loggedInUser &&
      loggedInUser.role.includes("super_user")
    ) {
      const jobUrl = getJobsUrl(meeting, loggedInUser);
      if (event.ctrlKey || event.metaKey) {
        window.open(jobUrl, "_blank");
        return;
      }
      history.push(getJobsUrl(job, loggedInUser));
      dispatch(clearMeetings());
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const approveMeeting = async (
    meeting: MeetingPreviewData,
    onSuccess: () => void
  ) => {
    try {
      setIsActionLoading(true);
      if (loggedInUser) {
        await FirebaseService.approveProofing(meeting);
      }
      onSuccess();
      const NewMeeting = { ...meeting, status: 5 };
      if (meeting.id) {
        dispatch(updateMeeting(NewMeeting));
      }
      dispatch(removeMeeting(meeting.id));
      dispatch(
        popIndicator({ type: "success", txt: t("indicator_sent_to_archive") })
      );
      onSuccess();
    } catch (err) {
      logger.log(err, "approveMeeting");
      onSuccess();
      dispatch(
        popIndicator({ type: "failure", txt: t("indicator_error_ocurred") })
      );
    } finally {
      setIsActionLoading(false);
    }
  };

  const sendToProof = async (
    meeting: MeetingPreviewData,
    onSuccess: () => void
  ) => {
    try {
      if (meeting.id && loggedInUser) {
        const info = {
          roomName: meeting.name,
          sentBy: {
            name: loggedInUser.username,
            id: loggedInUser.id,
          },
        };
        NotificationService.dispatchNotification(
          "sentToProof",
          "super_user",
          info
        );
        setIsActionLoading(true);
        await FirebaseService.deliverToProof(meeting.id);
        onSuccess();

        const updatedMeeting = { ...meeting, status: 4, assignedProofer: null };

        dispatch(updateMeeting(updatedMeeting));
        dispatch(
          popIndicator({ type: "success", txt: t("indicator_sent_to_proof") })
        );
      }
    } catch (err) {
      logger.log(err, "sendToProof");
      onSuccess();
      dispatch(
        popIndicator({ type: "failure", txt: t("indicator_error_ocurred") })
      );
    } finally {
      setIsActionLoading(false);
    }
  };

  const unassignMyself = async (
    meeting: MeetingPreviewData,
    onSuccess: () => void
  ) => {
    try {
      if (!loggedInUser) return;
      if (meeting?.id) {
        setIsActionLoading(true);
        await FirebaseService.unassignTranscriber(meeting.id);
        if (
          meeting.assignedMethod === "assigned" ||
          (meeting.assignedMethod === "requested" && meeting.isApproved)
        ) {
          if (meeting.assignedBy) {
            const recipient = meeting.assignedBy;
            const info = {
              roomName: meeting.name,
              sentBy: { name: loggedInUser.username, id: loggedInUser.id },
            };
            NotificationService.dispatchNotification(
              "transcriberReturnedMeeting",
              recipient,
              info
            );
          }
        }
        if (loggedInUser?.role === "transcriber" && meeting.isApproved) {
          await FirebaseService.increaseRejectedCount(loggedInUser.id);
        }
        onSuccess();
        dispatch(removeMeeting(meeting.id));
        dispatch(
          popIndicator({
            type: "success",
            txt: t("indicator_meeting_returned"),
          })
        );
      }
    } catch (err) {
      logger.log(err, "unassignMyself");
      onSuccess(); //todo check this
      dispatch(
        popIndicator({ type: "failure", txt: t("indicator_error_ocurred") })
      );
    } finally {
      setIsActionLoading(false);
    }
  };

  const onMeetingAction = selectedMeeting && {
    to_proof: () => sendToProof(selectedMeeting, onSuccess),
    to_send: () => approveMeeting(selectedMeeting, onSuccess),
    return_to_available_meetings: () =>
      unassignMyself(selectedMeeting, onSuccess),
    return_to_transcriber: () =>
      returnToTranscriber(selectedMeeting, onSuccess),
    unassign_transcriber_from_Meeting: () =>
      unAssignTranscriberFromMeeting(selectedMeeting, onSuccess, true),
    unassign_proofer_from_meeting: () =>
      unAssignProoferFromMeeting(selectedMeeting, onSuccess, true),
  };

  const exportTable = async () => {
    const formatMeetings = await getFormatMeetingsTable(meetings);

    if (formatMeetings) {
      const jobsIds = formatMeetings.map((job) => job.id);
      const jobsAuditEvents = await FirebaseService.getAuditEvents(jobsIds);
      const jobsWithAudit = await ExportService.getAuditEventsForExcel(
        formatMeetings,
        jobsAuditEvents,
        t
      );
      ExportService.exportDataToExcel(
        jobsWithAudit,
        "jobs",
        JobsPageExcelFields
      );
    }
  };

  return (
    <>
      {loggedInUser && (
        <main className="main-container">
          <PageHeader title={t("jobs")} />
          {!!loadingReason && <LoadingModal loadingReason={loadingReason} />}
          {!!errorReason && <ErrorModal errorReason={errorReason} />}
          {!loadingReason && !errorReason && (
            <div className="meetings-page flex column align-center">
              {meetingsTableData.length > 0 && (
                <div className="meetingsTable">
                  <MeetingsTable
                    additionalSearchFields={[
                      "job",
                      "lang",
                      "previewFormat",
                      "clientName",
                    ]}
                    data={meetingsTableData}
                    rawData={meetings}
                    onRowClick={goToMeeting}
                    orderTable={orderTable}
                    config={{
                      actions: {
                        export: {
                          label: "export",
                          icon: (
                            <FontAwesomeIcon icon={["far", "file-excel"]} />
                          ),
                          action: exportTable,
                          bulk: false,
                          hidden: loggedInUser?.role !== "super_user",
                        },
                      },
                      tableColumns: [
                        "id",
                        "name",
                        "translation",
                        "deadline",
                        "clientName",
                        "meetingLength",
                        "creationTime",
                        "speakers",
                        "lang",
                        "previewFormat",
                        "price",
                        "status",
                        "actions",
                        "processProgress",
                        "job",
                        "member",
                        "process",
                        "contextMenu",
                      ],
                      tableName: t("available_jobs"),
                      columns: {
                        status: { hidden: true },
                        clientName: {
                          hidden: true,
                        },
                        processProgressLastUpdate: {
                          hidden: loggedInUser?.role !== "super_user",
                        },
                        job: {
                          hidden:
                            loggedInUser?.role === "transcriber" ||
                            loggedInUser?.role === "proofer",
                        },
                        assignedTranscriber: {
                          hidden: loggedInUser?.role === "transcriber",
                        },
                        processProgress: {
                          hidden: loggedInUser.role !== "super_user",
                        },
                        price: {
                          hidden: loggedInUser.role === "super_user",
                        },
                        lang: {
                          hidden: true,
                        },
                        member: {
                          hidden: loggedInUser.role !== "super_user",
                        },
                        contextMenu: {
                          hidden: loggedInUser.role !== "super_user",
                        },
                      },
                      formatters: {
                        processProgress: (progress) =>
                          _.isNumber(progress) ? (
                            <CircleProgress progress={progress} />
                          ) : (
                            ""
                          ),
                      },
                    }}
                  />
                </div>
              )}
            </div>
          )}
          {meetingsTableData.length === 0 && !loadingReason && !errorReason && (
            <EmptyPageComp title={t("no_available_meetings")} />
          )}
          {showModal && selectedMeeting && (
            <Dialog
              className={getLanguageDirection(i18n.language)}
              open={showModal}
              onClose={() => setShowModal(false)}
            >
              {(selectedMeeting.status === 3 &&
                !selectedMeeting.assignedTranscriber) ||
              (selectedMeeting.status === 4 &&
                !selectedMeeting.assignedProofer) ? (
                <TranscribersList
                  close={() => setShowModal(false)}
                  setStatusClr={_.noop}
                  loggedInUser={loggedInUser}
                  transcribers={(selectedMeeting.status === 3
                    ? transcribers
                    : proofers
                  ).filter((user) => user.role !== "disabled" && user.username)}
                  meetingName={selectedMeeting.name}
                  meetingStatus={selectedMeeting.status}
                  isLoading={isActionLoading}
                  handleAssignedTranscriber={(transcriber) => {
                    if (selectedMeeting.status === 3) {
                      assignTranscriberToMeeting(
                        selectedMeeting,
                        transcriber,
                        closeModal,
                        true
                      );
                    } else {
                      assignProoferToMeeting(
                        selectedMeeting,
                        transcriber,
                        closeModal,
                        true
                      );
                    }
                  }}
                />
              ) : (
                <ApproveModal
                  type={approvalType[selectedButton as ApprovalType]}
                  info={{ meetingName: selectedMeeting.name }}
                  approveBtn={{
                    text: t("approve"),
                    look: "neutral",
                    action: _.get(
                      onMeetingAction,
                      selectedButton as ApprovalType
                    ),
                  }}
                  declineBtn={{
                    text: t("cancel"),
                    look: "approve",
                    action: () => setShowModal(false),
                  }}
                  isPending={isActionLoading}
                  specificClass={"cancelAbsolute"}
                ></ApproveModal>
              )}
            </Dialog>
          )}
          {isEditModalOpen && selectedMeeting && (
            <EditModal
              handleSubmit={editJob}
              fieldsToEdit={[
                "name",
                "jobType",
                "lang.input",
                "lang.output",
                "price",
                "prooferPrice",
                "deadline",
                "processProgressLastUpdate",
              ]}
              job={selectedMeeting}
              showModal={isEditModalOpen}
              closeModal={() => setIsEditModalOpen(false)}
            ></EditModal>
          )}
          {selectedMeeting && isNoteModalOpen && (
            <NoteModal
              jobId={selectedMeeting.id}
              notes={selectedMeeting.notes || ""}
              showModal={isNoteModalOpen}
              closeModal={() => setIsNoteModalOpen(false)}
              onSubmit={async (note) => {
                await FirebaseService.updateNotes(note, selectedMeeting.id);
                selectedMeeting.notes = note;
                dispatch(updateMeeting(selectedMeeting));
                setIsNoteModalOpen(false);
              }}
            ></NoteModal>
          )}
          {selectedMeeting && showSplitModal && (
            <SplitModalModal
              jobLength={selectedMeeting.meetingLength}
              splitJob={splitJob}
              showModal={showSplitModal}
              closeModal={() => setShowSplitModal(false)}
            />
          )}
          {meetingId && jobModalOpen && (
            <JobAuditModal
              id={meetingId}
              setShowModal={setJobModalOpen}
              showModal={jobModalOpen}
            ></JobAuditModal>
          )}
        </main>
      )}
    </>
  );
};

export default MeetingsPage;
