import axios from "axios";
import _ from "lodash";
import config from "../config";

class ElasticSearchService {
  private static instance: ElasticSearchService;

  public baseUrl: string;
  public headers: { [key: string]: string };

  public defaultChunkSize = 25;

  private constructor() {
    this.baseUrl = config.appSearch.url;
    this.headers = {
      "Content-Type": "application/json",
      Authorization: config.appSearch.publicKey,
    };
  }

  public filterFormater = (rawFilters: {
    [key: string]: {
      value: string | string[] | number | number[] | Date;
      type: "normal" | "range";
    };
  }) => {
    const query = [];
    for (const key in rawFilters) {
      const value = rawFilters[key].value;
      const type = rawFilters[key].type;
      if (type === "range" && _.isArray(value)) {
        query.push({
          [key]: {
            from: value[0],
            to: value[1],
          },
        });
      } else if (type === "normal") {
        query.push({
          [key]: value,
        });
      }
    }
    return query;
  };

  private query = async (
    { filters = [], searchValue = "", page = 1 }: any,
    engine: string,
    dataBackPerCall?: number
  ) => {
    if (dataBackPerCall) {
      this.defaultChunkSize = dataBackPerCall;
    }
    const searchQuery = {
      query: searchValue,
      page: {
        size: dataBackPerCall ? dataBackPerCall : this.defaultChunkSize,
        current: page,
      },
      sort: [
        {
          creationtime: "asc",
        },
      ],
      filters: {
        all: filters,
      },
    };
    const res = await axios.post(
      `${this.baseUrl}${engine}/search`,
      searchQuery,
      {
        headers: this.headers,
      }
    );
    const results = res.data.results.map((result: any) => {
      const data = {};
      for (const key in result) {
        //@ts-ignore
        data[key] = result[key].raw;
      }
      return data;
    });
    const resTotalPages = res.data.meta.page.total_pages;
    const resTotalResults = res.data.meta.page.total_results;

    return { results, resTotalPages, resTotalResults };
  };

  public getJobs = async (jobFilters: any, size?: number) => {
    const res = await this.query(jobFilters, config.appSearch.rooms, size);
    return res;
  };

  public static getInstance = () => {
    if (!ElasticSearchService.instance)
      ElasticSearchService.instance = new ElasticSearchService();
    return ElasticSearchService.instance;
  };
}

export default ElasticSearchService.getInstance();
