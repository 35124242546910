import config from "./config";
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/browser";
import mixpanel from "mixpanel-browser";
import { Integrations } from "@sentry/tracing";
import { Replay } from "@sentry/browser";
import "./i18n";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store/store";
import reportWebVitals from "./reportWebVitals";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fal } from "@fortawesome/pro-light-svg-icons";
import { far } from "@fortawesome/pro-regular-svg-icons";
import { fas } from "@fortawesome/pro-solid-svg-icons";
import { version } from "../package.json";

library.add(fal, far, fas);

mixpanel.init(config.mixpanel.projectToken, {
  debug: config.mixpanel.debug,
});

Sentry.init({
  dsn:
    "https://dfa84206972047949f0bdcd2b15e0f03@o689365.ingest.sentry.io/5855873",
  integrations: [
    new Replay({
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],
  replaysOnErrorSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  tracesSampleRate: 0.5,
  environment: process.env.REACT_APP_ENV,
  release: version,
});

ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback="">
      <Router>
        <App />
      </Router>
    </Suspense>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
