import React, { FC, useEffect, useState, useRef } from "react";
import _ from "lodash";
import i18n from "../../i18n";
import { format } from "date-fns";
import { Button } from "@material-ui/core";

import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Dialog from "@material-ui/core/Dialog";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { AppState } from "../../store/rootReducer";
import {
  clearMeetings,
  setMeetings,
  updateMeeting,
  removeMeeting,
} from "../../store/meeting/actions";
import MeetingActions from "./MeetingActions";
import { getProofers, getTranscribers } from "../../store/user/actions";
import {
  setLoadingReason,
  resetSorting,
  clearLoadingReason,
  popIndicator,
  setErrorReason,
} from "../../store/system/actions";
import {
  MeetingPreviewData,
  MeetingTableItem,
  ElasticJob,
} from "../../models/meeting";
import { MinimalProofer, MinimalTranscriber } from "../../models/user";
import { JobType, jobTypes } from "../../models/jobTypes";
import { DocumentReference, DocumentData } from "@firebase/firestore-types";

import { getLanguageDirection, getLanguages } from "../../utils/locales";
import NotificationService from "../../services/NotificationService";
import TimeService from "../../services/TimeService";
import MeetingService from "../../services/MeetingService";
import { FirebaseService } from "../../services/ServiceControler";
import * as SettingService from "../../services/SettingService";
import FeatureFlagsService from "../../services/FeatureFlagsService";
import ExportService from "../../services/ExportService";
import EditorService from "../../services/EditorService";

import PageHeader from "../../components/PageHeader/PageHeader";
import LoadingModal from "../../components/LoadingModal/LoadingModal";
import ErrorModal from "../../components/ErrorModal/ErrorModal";
import EmptyPageComp from "../../components/EmptyPageComp/EmptyPageComp";
import MeetingsTable from "../../components/MeetingsTable/MeetingsTable";
import TranscribersList from "../../components/MeetingPreview/TranscribersList";
import NoteModal from "../../components/Modals/NoteModal/NoteModal";
import CircleProgress from "../../components/common/CircleProgress/CircleProgress";
import EditModal from "../../components/Modals/EditModal/EditModal";
import ApproveModal from "../../components/MeetingPreview/ApproveModal";
import JobAuditModal from "../../components/common/JobAuditModal/JobAuditModal";

import ContextMenu from "../ArchivePage/ContextMenu";
import { getJobsUrl } from "../../utils/url";
import { numberWithCommas } from "../../utils/formatters";
import { JobsPageExcelFields } from "../../constants/excelFields";

import Logger from "../../services/Logger";

import ValidationsConfigModal from "../ValidationsConfig/ValidationsConfigModal";
import LabelField from "../../components/common/LabelField/LabelField";
import EditableText from "../../components/EditableText/EditableText";
import OnClickDropdown from "../../components/common/OnClickDropdown/OnClickDropdown";
import { CircularProgress } from "@material-ui/core";
import classNames from "classnames";
import ExportModal from "../../components/common/ExportModal/ExportModal";
import TranslationJobModal from "../../components/Modals/TranslationJobModal/TranslationJobModal";
import { JobData } from "../../models/job";
import ExportModalV3 from "../../components/common/ExportModalV3/ExportModalV3";

import { ExportConfigData } from "../ExportConfig/ExportConfigTypes";
import GenericTable from "../../components/Table/GenericTable";

import "./MeetingsPages.scss";

const logger = Logger("ClientMeetingsPage");

type ApprovalType =
  | "to_proof"
  | "to_send"
  | "return_to_available_meetings"
  | "return_to_transcriber"
  | "unassign_transcriber_from_Meeting"
  | "unassign_proofer_from_meeting";
class Action {
  type: string;
  meeting?: MeetingPreviewData;
  meetingId?: string;
  transcriberId?: string;
  onSuccess: () => void;
}

const ClientMeetingsPage: FC = (): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const loggedInUser = useSelector(
    (state: AppState) => state.userStore.loggedInUser
  );
  const loadingReason = useSelector(
    (state: AppState) => state.systemStore.loadingReason
  );
  const { id } = useParams<Record<string, string>>();
  const errorReason = useSelector(
    (state: AppState) => state.systemStore.errorReason
  );
  const meetings = useSelector(
    (state: AppState) => state.meetingStore.meetings
  );
  const transcribers = useSelector(
    (state: AppState) => state.userStore.transcribers
  );
  const proofers = useSelector((state: AppState) => state.userStore.proofers);

  const [meetingsTableData, setMeetingsTableData] = useState<
    MeetingTableItem[]
  >([]);
  const sorting = useSelector((state: AppState) => state.systemStore.sorting);
  const [selectedButton, setSelectedButton] = useState("");
  const [
    selectedMeeting,
    setSelectedMeeting,
  ] = useState<MeetingPreviewData | null>(null);
  const selectedJobs = useRef<any[]>([]);
  const setSelectedJobs = (jobs: any) => {
    selectedJobs.current = jobs;
  };
  const [actionMeetings, setActionMeetings] = useState<
    { id: string; name: string }[] | undefined
  >();

  const [clientDocxPresets, setClientDocxsPresets] = useState<
    { name: undefined | string; id: string; config: ExportConfigData }[]
  >([]);

  const [
    validationModalJobType,
    setValidationModalJobType,
  ] = useState<JobType>();
  const [validationsModalIsOpen, setValidationsModalIsOpen] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isNoteModalOpen, setIsNoteModalOpen] = useState(false);
  const [isActionLoading, setIsActionLoading] = useState(false);
  const [jobModalOpen, setJobModalOpen] = useState(false);
  const [translationJobModal, setTranslationJobModal] = useState(false);
  const [openExportModal, setOpenExportModal] = useState(false);
  const [meetingId, setMeetingId] = useState<string>();
  const [clientDetails, setClientDetails] = useState<any>();
  const onSuccess = () => {
    setIsActionLoading(false);
    closeModal();
  };

  const [openExportV3Modal, setOpenExportV3Modal] = useState(false);
  const [exportV3JobsData, setExportV3JobsData] = useState<JobData[]>([]);
  const [exportConfig, setExportConfig] = useState<ExportConfigData>();
  const [exportConfigPresets, setExportConfigPresets] = useState<
    { id: string; config: ExportConfigData }[]
  >([]);
  const [showArchivedJobs, setShowArchivedJobs] = useState(false);

  const loadClientDetails = async () => {
    if (!loggedInUser) return;
    try {
      dispatch(setLoadingReason(t("loading client meetings")));
      const clientRef = FirebaseService.getDocRef(
        FirebaseService.collections.clients,
        id
      );
      const clientDoc = await clientRef.get();
      const clientData = clientDoc.data();
      if (clientData) {
        setClientDetails(clientData);
      }

      dispatch(clearLoadingReason());
    } catch (err) {
      logger.log(err, "loadClientDetails");
      dispatch(setErrorReason(t("indicator_error_ocurred")));
      dispatch(clearLoadingReason());
    }
  };

  useEffect(() => {
    const fetchClientDocxPresets = async () => {
      if (id) {
        const presets = await FirebaseService.getClientExportConfigPresets(id);
        const clientDocxPresetsNew = presets.map((p) => ({
          name: p.config.general.presetName,
          ...p,
        }));
        setClientDocxsPresets(clientDocxPresetsNew);
      }
    };
    fetchClientDocxPresets();
  }, [id]);

  useEffect(() => {
    return () => {
      dispatch(clearMeetings());
    };
  }, []);
  useEffect(() => {
    if (sorting.type !== "meetings") dispatch(resetSorting("meetings"));
    if (loggedInUser) {
      if (
        ["transcriber+proofer", "super_user", "proofer"].includes(
          loggedInUser.role
        )
      ) {
        dispatch(getTranscribers());
        if ("super_user" === loggedInUser.role) {
          dispatch(getProofers());
        }
      }
      loadClientMeetings();
      loadClientDetails();
    }
    return () => {
      dispatch(clearMeetings());
    };
  }, [loggedInUser]);

  useEffect(() => {
    if (loggedInUser) {
      const formatMeeting = async () => {
        const formatMeetings = await getFormatMeetingsTable(meetings);
        if (formatMeetings) {
          setMeetingsTableData(formatMeetings);
        }
      };
      formatMeeting();
    }
  }, [meetings]);

  const getCurrDebt = (meetings: MeetingPreviewData[]) => {
    const clientDebtSum = _.sumBy(meetings, (o) =>
      Number(o.revenue) ? Number(o.revenue) : 0
    );
    const currDebt = clientDebtSum ? numberWithCommas(clientDebtSum) : 0;
    return currDebt;
  };

  const clientsFields = [
    {
      type: "EditableText",
      label: t("account_name"),
      fieldName: "accountName",
      inputType: "text",
    },
    {
      type: "OnClickDropdown",
      label: t("pricing_type"),
      options: [
        {
          label: t("minutes"),
          value: "minutes",
          icon: "",
        },
        {
          label: t("price_list"),
          value: "price_list",
          icon: "",
        },
        {
          label: t("pages"),
          value: "pages",
          icon: "",
        },
        {
          label: t("fix_price"),
          value: "fix_price",
          icon: "",
        },
      ],
      fieldName: "pricingType",
      className: "whiteBackground",
    },
    {
      label: t("representative_name"),
      type: "EditableText",
      className: "margin-rigth",
      fieldName: "representativeName",
      inputType: "text",
    },
    {
      label: t("bn_number"),
      type: "EditableText",
      fieldName: "bnNumber",
      inputType: "number",
    },
    {
      label: t("hourly_rate"),
      prefix: t("currency"),
      type: "EditableText",
      fieldName: "hourlyRate",
      inputType: "number",
    },
    {
      label: t("representative_phone_number"),
      type: "EditableText",
      fieldName: "representativePhoneNumber",
      className: "margin-rigth",
      inputType: "text",
    },
    {
      type: "OnClickDropdown",
      label: t("client_type"),
      options: [
        { label: "B2C", value: "B2C", icon: "" },
        { label: "B2B", value: "B2B", icon: "" },
        { label: "B2G", value: "B2G", icon: "" },
      ],
      fieldName: "clientType",
      className: "whiteBackground",
    },
    {
      type: "EditableText",
      fieldName: "hourOrLessRate",
      prefix: t("currency"),
      label: t("hour_or_less_rate"),
      inputType: "number",
    },
    {
      label: t("mail"),
      type: "EditableText",
      fieldName: "mail",
      className: "margin-rigth",
      inputType: "text",
    },
    {
      label: t("current_debt"),
      field: (
        <div className="field">
          {getCurrDebt(meetings)} {t("ils")}
        </div>
      ),
      className: "currentDebt",
      fieldName: "currentDebt",
      inputType: "number",
    },
    {
      label: t("representative_rate"),
      prefix: t("currency"),
      type: "EditableText",
      fieldName: "representativeRate",
      className: "margin-rigth",
      inputType: "number",
    },
    {
      type: "OnClickDropdown",
      label: t("vat"),
      options: [
        {
          label: t("include"),
          value: true,
          icon: "",
        },
        {
          label: t("not_include"),
          value: false,
          icon: "",
        },
      ],
      placeholder: () => {
        return clientDetails?.vat === undefined
          ? t("not_entered_yet")
          : clientDetails.vat
          ? t("include")
          : t("not_include");
      },
      className: "vat",
      fieldName: "vat",
    },
    {
      fieldName: "notes",
      field: (
        <div className="notes">
          <div className="label">{t("notes")}</div>
          {
            <div className="textarea-container">
              <textarea
                disabled={disabled}
                value={clientDetails?.notes ? clientDetails.notes : ""}
                onChange={(e) => {
                  const updatedClientDetails = { ...clientDetails };
                  updatedClientDetails.notes = e.target.value;
                  setClientDetails(updatedClientDetails);
                }}
                onBlur={async () => {
                  setDisabled(true);
                  await FirebaseService.changeClientField(
                    id,
                    clientDetails.notes,
                    "notes"
                  );
                  setDisabled(false);
                }}
              ></textarea>
              {disabled && (
                <div
                  className={classNames(
                    "loading-spinner textarea-spinner",
                    getLanguageDirection(i18n.language)
                  )}
                >
                  <CircularProgress style={{ width: "20px" }} />
                </div>
              )}
            </div>
          }
        </div>
      ),
      className: "note-container",
    },
  ];

  const loadClientMeetings = async () => {
    try {
      if (loggedInUser) {
        dispatch(setLoadingReason(t("loading_client_jobs")));
        const meetings = await FirebaseService.getClientJobs(loggedInUser, id);

        if (meetings) {
          dispatch(setMeetings(meetings));
          const formatMeetings = await getFormatMeetingsTable(meetings);
          if (formatMeetings) {
            setMeetingsTableData(formatMeetings);
          }
        }
        dispatch(clearLoadingReason());
      }
    } catch (err) {
      console.log(err);
      dispatch(setErrorReason(t("indicator_error_ocurred")));
      dispatch(clearLoadingReason());
    }
  };
  const orderTable = (property: string, descOrAsc: "desc" | "asc") => {
    SettingService.setSettings("orderBy", property, dispatch);
    SettingService.setSettings("order", descOrAsc, dispatch);
  };

  const editJob = (newJob: MeetingPreviewData | ElasticJob) => {
    dispatch(updateMeeting(newJob));
  };
  const getTextualStatus = (
    status: number,
    assignedTranscriber: DocumentReference<DocumentData> | null,
    assignedProofer: DocumentReference<DocumentData> | null | undefined
  ) => {
    if (status === 3) {
      return assignedTranscriber ? "at_work" : "open";
    } else if (status === 4) {
      return assignedProofer ? "reviewing" : "pending";
    } else if (status === 1) {
      return "processing";
    } else {
      return "ready";
    }
  };

  const updateClientField = async (
    newValue: string | number | boolean,
    fieldName: string
  ) => {
    try {
      const updatedClientDetails = { ...clientDetails };
      if (
        updatedClientDetails &&
        _.map(clientsFields, "fieldName").includes(fieldName)
      ) {
        await FirebaseService.changeClientField(id, newValue, fieldName);
        updatedClientDetails[fieldName] = newValue;
        setClientDetails(updatedClientDetails);
        return true;
      }
      throw "wrong fieldName";
    } catch (error) {
      logger.log(error, "updateClientField");
      return false;
    }
  };

  const invoiceSentUpdate = async (value: string, job: MeetingPreviewData) => {
    try {
      dispatch(popIndicator({ type: "info", txt: t("update_invoice") }));
      await FirebaseService.changeInvoiceSent(job.id, value);
      dispatch(popIndicator({ type: "success", txt: t("invoice_updated") }));
      const updatedJob = _.update(job, "invoiceSent", (preValue) =>
        value ? value : preValue
      );
      await editJob(updatedJob);
    } catch (error) {
      dispatch(
        popIndicator({ type: "failure", txt: t("update_invoice_failed") })
      );
      console.log(error);
    }
    return true;
  };

  const deletePreset = async (e: any, presetId: string) => {
    e.stopPropagation();
    await FirebaseService.deleteClientExportConfigPreset(id, presetId);
    const clientDocxPresetsUpdated = clientDocxPresets.filter(
      (pUpdated) => pUpdated.id !== presetId
    );
    setClientDocxsPresets(clientDocxPresetsUpdated);
  };

  const generateMenuItems = (meeting: MeetingPreviewData) => {
    let menuItems = [
      {
        onClick: () => {
          setSelectedMeeting(meeting);
          setIsEditModalOpen(true);
        },
        text: t("edit"),
      },
      {
        onClick: () => {
          setSelectedMeeting(meeting);
          setIsNoteModalOpen(true);
        },
        text: t("notes"),
      },
      {
        onClick: () => {
          setMeetingId(meeting.id);
          setJobModalOpen(true);
        },
        text: t("audit"),
      },
    ];

    if (meeting.status === 5) {
      menuItems = [
        ...menuItems,
        ...([
          {
            onClick: () => {
              setActionMeetings([{ id: meeting.id, name: meeting.name }]);
              setOpenExportModal(true);
            },
            text: t("export"),
          },
          {
            onClick: () => {
              invoiceSentUpdate && invoiceSentUpdate("open", meeting);
            },
            text: t("open"),
          },
          {
            onClick: () => {
              invoiceSentUpdate && invoiceSentUpdate("check", meeting);
            },
            text: t("check"),
          },
          {
            onClick: () => {
              invoiceSentUpdate && invoiceSentUpdate("sent", meeting);
            },
            text: t("sent"),
          },
          {
            onClick: () => {
              setMeetingId(meeting.id);
              setTranslationJobModal(true);
            },
            text: t("createTranslation"),
          },
        ] as {
          onClick: () => void;
          text: string;
        }[]),
      ];
    }

    if (meeting.transcriberId) {
      menuItems.push({
        onClick: () => {
          history.push(`/transcriber/${meeting.transcriberId}`);
        },
        text: t("transcriber"),
      });
    }
    if (meeting.reviewerId) {
      menuItems.push({
        onClick: () => {
          history.push(`/transcriber/${meeting.reviewerId}`);
        },
        text: t("proofer"),
      });
    }

    return menuItems;
  };

  const getFormatMeetingsTable = async (meetings: MeetingPreviewData[]) => {
    try {
      const formatMeetings = await Promise.all(
        meetings.map(async (meeting) => {
          const {
            id,
            name,
            deadline,
            clientDeadline,
            invoiceSent,
            meetingLength,
            processProgressLastUpdate,
            speakers,
            assignedTranscriber,
            lang,
            editProgress,
            jobType: previewFormat,
            assignedProofer,
            price,
            translation,
            prooferPrice,
            creationTime,
            status,
            process,
            workTime,
            v3id,
          } = meeting;
          const transcriberId = assignedTranscriber?.id;
          const transName =
            transcriberId && transcribers
              ? MeetingService.getTranscriberFromTranscribers(
                  transcriberId,
                  transcribers,
                  "username"
                )
              : "";
          let langText = "-";
          const input = t(lang.input[0]);
          const output = t(lang.output[0]);
          if (lang) {
            langText =
              previewFormat === "subtitles" ? input : `${input} - ${output}`;
          }

          const prooferId = assignedProofer?.id;
          const prooferName =
            prooferId && proofers
              ? MeetingService.getMember(prooferId, proofers, "username")
              : "";
          const statusText = getTextualStatus(
            status,
            assignedTranscriber,
            assignedProofer
          );

          const transcribingTotalTime = EditorService.totalWorkTime(
            workTime?.transcribe
          );
          const reviewingTotalTime = EditorService.totalWorkTime(
            workTime?.review
          );

          const menuItems = generateMenuItems(meeting);

          const meetingObj = {
            translation,
            speakers: speakers ? speakers.length : 0,
            lang: langText,
            reviewTimeSync: reviewingTotalTime.timeSyncSum,
            reviewTextEditTime: reviewingTotalTime.textEditTimeSum,
            transcribeTimeSync: transcribingTotalTime.timeSyncSum,
            transcribeTextEditTime: transcribingTotalTime.textEditTimeSum,
            processProgress:
              status === 1 ? Number(process.progress) : editProgress,
            processProgressLastUpdate,
            deadline: deadline,
            clientDeadline,
            uploadDate: creationTime,
            creationTime: creationTime ? creationTime : "-",
            meetingLength: meetingLength
              ? TimeService.getTimeStringFromSecs(meetingLength)
              : "-",
            fileLength: deadline
              ? TimeService.getDeadlineString(deadline)
              : "0",
            job: statusText,
            jobStatus: t(statusText),
            prooferPrice: prooferPrice ? prooferPrice : 0,
            assignedProofer: prooferName ? prooferName : t("no_proofer"),
            price: price ? price : 0,
            assignedTranscriber: transName ? transName : t("no_transcriber"),
            output: previewFormat === "subtitles" ? input : output,
            input: input,
            status,
            invoiceSent: meeting.status === 5 && invoiceSent,
            previewFormat: t(previewFormat),
            name,
            id,
            v3id,
            contextMenu: <ContextMenu menuItems={menuItems}></ContextMenu>,
            actions: (
              <MeetingActions
                setSelectedMeeting={setSelectedMeeting}
                setSelectedButton={setSelectedButton}
                setShowModal={setShowModal}
                meeting={meeting}
              />
            ),
          };
          return meetingObj;
        })
      );
      return formatMeetings;
    } catch (err) {
      logger.log(err, "getFormatMeetingsTable");
    }
  };

  const assignTranscriberToMeeting = async (
    meeting: MeetingPreviewData,
    transcriber: MinimalTranscriber,
    onSuccess: () => void,
    recordsRemain = false
  ) => {
    if (!loggedInUser) return;
    try {
      const method =
        transcriber.id === loggedInUser?.id ? "claimed" : "assigned";
      if (loggedInUser) setIsActionLoading(true);
      await FirebaseService.assignTranscriber(
        meeting.id,
        transcriber.id,
        method
      );
      if (method === "claimed" && !recordsRemain)
        dispatch(removeMeeting(meeting.id));
      else {
        const updatedMeeting = { ...meeting };
        updatedMeeting.assignedTranscriber = FirebaseService.getTranscriberRef(
          transcriber.id
        );
        updatedMeeting.transcriberId = transcriber.id;

        if (method !== "claimed") {
          updatedMeeting.assignedMethod = "assigned";
        } else {
          updatedMeeting.assignedMethod = "claimed";
          updatedMeeting.isApproved = true;
        }
        dispatch(updateMeeting(updatedMeeting));
        const recipient = FirebaseService.getDocRef("users-v2", transcriber.id);
        const info = {
          roomName: meeting.name,
          sentBy: {
            name: loggedInUser.username,
            id: loggedInUser.id,
          },
        };
        NotificationService.dispatchNotification(
          "gotAssigned",
          recipient,
          info
        );
      }
      onSuccess();
      dispatch(
        popIndicator({ type: "success", txt: t("indicator_assign_success") })
      );
    } catch (err) {
      console.log(err);
      onSuccess();
      dispatch(
        popIndicator({ type: "failure", txt: t("indicator_assign_failure") })
      );
    } finally {
      setIsActionLoading(false);
    }
  };

  const assignProoferToMeeting = async (
    meeting: MeetingPreviewData,
    proofer: MinimalProofer,
    onSuccess: () => void,
    recordsRemain = false
  ) => {
    if (!loggedInUser) return;
    try {
      const method = proofer.id === loggedInUser?.id ? "claimed" : "assigned";
      if (loggedInUser) setIsActionLoading(true);
      await FirebaseService.assignProofer(meeting.id, proofer.id);
      if (!recordsRemain) dispatch(removeMeeting(meeting.id));
      else {
        const updatedMeeting = { ...meeting };
        updatedMeeting.assignedProofer = FirebaseService.getTranscriberRef(
          proofer.id
        );
        updatedMeeting.reviewerId = proofer.id;
        if (method !== "claimed") {
          updatedMeeting.assignedMethod = "assigned";
        } else {
          updatedMeeting.assignedMethod = "claimed";
          updatedMeeting.isApproved = true;
        }
        dispatch(updateMeeting(updatedMeeting));
        const recipient = FirebaseService.getDocRef("users-v2", proofer.id);
        const info = {
          roomName: meeting.name,
          sentBy: {
            name: loggedInUser.username,
            id: loggedInUser.id,
          },
        };
        NotificationService.dispatchNotification(
          "gotAssigned",
          recipient,
          info
        );
      }
      onSuccess();
      dispatch(
        popIndicator({ type: "success", txt: t("indicator_assign_success") })
      );
    } catch (err) {
      console.log(err);
      onSuccess();
      dispatch(
        popIndicator({ type: "failure", txt: t("indicator_assign_failure") })
      );
    } finally {
      setIsActionLoading(false);
    }
  };

  const unAssignProoferFromMeeting = async (
    meeting: MeetingPreviewData,
    onSuccess: () => void,
    recordsRemain = false
  ) => {
    try {
      if (!loggedInUser) return;
      setIsActionLoading(true);
      await FirebaseService.unAssignProofer(meeting.id);
      onSuccess();
      if (!recordsRemain) {
        dispatch(removeMeeting(meeting.id));
        setMeetingsTableData(
          meetingsTableData.filter((meeting) => meeting.id !== meeting.id)
        );
      } else {
        const updatedMeeting = {
          ...meeting,
          assignedProofer: null,
          reviewerId: null,
        };
        dispatch(updateMeeting(updatedMeeting));
      }
      dispatch(
        popIndicator({ type: "success", txt: t("indicator_meeting_returned") })
      );
    } catch (err) {
      logger.log(err, "unAssignProoferFromMeeting");
      onSuccess();
      dispatch(
        popIndicator({ type: "failure", txt: t("indicator_error_ocurred") })
      );
    }
  };

  const unAssignTranscriberFromMeeting = async (
    meeting: MeetingPreviewData,
    onSuccess: () => void,
    recordsRemain = false
  ) => {
    try {
      if (meeting.id) {
        setIsActionLoading(true);
        loggedInUser && (await FirebaseService.unassignTranscriber(meeting.id));
        if (!recordsRemain) {
          dispatch(removeMeeting(meeting.id));
          setMeetingsTableData(
            meetingsTableData.filter(
              (tableMeeting) => tableMeeting.id !== meeting.id
            )
          );
        } else {
          const updatedMeeting = {
            ...meeting,
            assignedTranscriber: null,
            assignedMethod: null,
            transcriberId: null,
            assignedB: null,
            isApproved: false,
          };
          dispatch(updateMeeting(updatedMeeting));
        }
        dispatch(
          popIndicator({
            type: "success",
            txt: t("indicator_request_declined"),
          })
        );
        onSuccess();
      }
    } catch (err) {
      logger.log(err, "unAssignTranscriberFromMeeting");
      onSuccess(); //todo check this
      dispatch(
        popIndicator({ type: "failure", txt: t("indicator_error_ocurred") })
      );
    }
  };
  const approvalType = {
    to_proof: "send_to_proof",
    to_send: "approve_meeting",
    return_to_available_meetings: "release_meeting",
    return_to_transcriber: "return_to_transcriber",
    unassign_transcriber_from_Meeting: "release_from_transcriber",
    unassign_proofer_from_meeting: "release_from_proofer",
  };

  const goToMeeting = (
    meeting: { [key: string]: any },
    event: React.MouseEvent
  ) => {
    const job = meetings.find((job) => job.id === meeting.id);
    if (
      meeting.status >= 3 &&
      loggedInUser &&
      loggedInUser.role.includes("super_user")
    ) {
      const jobUrl = getJobsUrl(meeting, loggedInUser);
      if (event.ctrlKey || event.metaKey) {
        window.open(jobUrl, "_blank");
        return;
      }

      history.push(jobUrl);
      dispatch(clearMeetings());
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const approveMeeting = async (
    meeting: MeetingPreviewData,
    onSuccess: () => void
  ) => {
    try {
      setIsActionLoading(true);
      if (loggedInUser) {
        await FirebaseService.approveProofing(meeting);
      }
      onSuccess();
      const NewMeeting = { ...meeting, status: 5 };
      if (meeting.id) {
        dispatch(updateMeeting(NewMeeting));
      }
      dispatch(removeMeeting(meeting.id));
      dispatch(
        popIndicator({ type: "success", txt: t("indicator_sent_to_archive") })
      );
      onSuccess();
    } catch (err) {
      logger.log(err, "approveMeeting");
      onSuccess();
      dispatch(
        popIndicator({ type: "failure", txt: t("indicator_error_ocurred") })
      );
    } finally {
      setIsActionLoading(false);
    }
  };

  const returnToTranscriber = async (
    meeting: MeetingPreviewData,
    onSuccess: () => void
  ) => {
    try {
      if (!loggedInUser) return;
      setIsActionLoading(true);
      await FirebaseService.returnToTranscriber(meeting);
      onSuccess();
      const updatedMeeting = {
        ...meeting,
        status: 3,
      };
      dispatch(updateMeeting(updatedMeeting));
      dispatch(
        popIndicator({ type: "success", txt: t("indicator_meeting_returned") })
      );
    } catch (err) {
      logger.log(err, "returnToTranscriber");
      onSuccess();
      dispatch(
        popIndicator({ type: "failure", txt: t("indicator_error_ocurred") })
      );
    }
  };

  const sendToProof = async (
    meeting: MeetingPreviewData,
    onSuccess: () => void
  ) => {
    try {
      if (meeting.id && loggedInUser) {
        const info = {
          roomName: meeting.name,
          sentBy: {
            name: loggedInUser.username,
            id: loggedInUser.id,
          },
        };
        NotificationService.dispatchNotification(
          "sentToProof",
          "super_user",
          info
        );
        setIsActionLoading(true);
        await FirebaseService.deliverToProof(meeting.id);
        onSuccess();

        const updatedMeeting = { ...meeting, status: 4, assignedProofer: null };

        dispatch(updateMeeting(updatedMeeting));
        dispatch(
          popIndicator({ type: "success", txt: t("indicator_sent_to_proof") })
        );
      }
    } catch (err) {
      logger.log(err, "sendToProof");
      onSuccess();
      dispatch(
        popIndicator({ type: "failure", txt: t("indicator_error_ocurred") })
      );
    } finally {
      setIsActionLoading(false);
    }
  };

  const unassignMyself = async (
    meeting: MeetingPreviewData,
    onSuccess: () => void
  ) => {
    try {
      if (!loggedInUser) return;
      if (meeting?.id) {
        setIsActionLoading(true);
        await FirebaseService.unassignTranscriber(meeting.id);
        if (
          meeting.assignedMethod === "assigned" ||
          (meeting.assignedMethod === "requested" && meeting.isApproved)
        ) {
          if (meeting.assignedBy) {
            const recipient = meeting.assignedBy;
            const info = {
              roomName: meeting.name,
              sentBy: { name: loggedInUser.username, id: loggedInUser.id },
            };
            NotificationService.dispatchNotification(
              "transcriberReturnedMeeting",
              recipient,
              info
            );
          }
        }
        if (loggedInUser?.role === "transcriber" && meeting.isApproved) {
          await FirebaseService.increaseRejectedCount(loggedInUser.id);
        }
        onSuccess();
        dispatch(removeMeeting(meeting.id));
        dispatch(
          popIndicator({
            type: "success",
            txt: t("indicator_meeting_returned"),
          })
        );
      }
    } catch (err) {
      logger.log(err, "unassignMyself");
      onSuccess(); //todo check this
      dispatch(
        popIndicator({ type: "failure", txt: t("indicator_error_ocurred") })
      );
    } finally {
      setIsActionLoading(false);
    }
  };

  const onMeetingAction = selectedMeeting && {
    to_proof: () => sendToProof(selectedMeeting, onSuccess),
    to_send: () => approveMeeting(selectedMeeting, onSuccess),
    return_to_available_meetings: () =>
      unassignMyself(selectedMeeting, onSuccess),
    return_to_transcriber: () =>
      returnToTranscriber(selectedMeeting, onSuccess),
    unassign_transcriber_from_Meeting: () =>
      unAssignTranscriberFromMeeting(selectedMeeting, onSuccess, true),
    unassign_proofer_from_meeting: () =>
      unAssignProoferFromMeeting(selectedMeeting, onSuccess, true),
  };

  const exportTable = async () => {
    const formatMeetings = await getFormatMeetingsTable(meetings);

    if (formatMeetings) {
      const jobsIds = formatMeetings.map((job) => job.id);
      const jobsAuditEvents = await FirebaseService.getAuditEvents(jobsIds);
      const jobsWithAudit = await ExportService.getAuditEventsForExcel(
        formatMeetings,
        jobsAuditEvents,
        t
      );
      ExportService.exportDataToExcel(
        jobsWithAudit,
        "jobs",
        JobsPageExcelFields
      );
    }
  };

  const handleJobSelection = (jobIds: string[]) => {
    setSelectedJobs(jobIds);
  };

  const exportV3 = async () => {
    if (_.isEmpty(selectedJobs.current)) return;

    dispatch(
      popIndicator({
        type: "info",
        txt: t("export_downloading_jobs"),
        sticky: true,
      })
    );

    const clientId = id;
    const newExportConfigPresets = await FirebaseService.getClientExportConfigPresets(
      clientId
    );
    let newClientExportConfig = await FirebaseService.getClientExportConfigData(
      clientId,
      "default"
    );
    if (!newClientExportConfig) {
      newClientExportConfig = await FirebaseService.getExportConfigData();
    }
    const jobIds = selectedJobs.current.map((j) => j.id);
    const jobsData = await EditorService.getUniqMergedJobs(jobIds);
    setExportConfig(newClientExportConfig);
    setExportConfigPresets(newExportConfigPresets || []);
    setExportV3JobsData(jobsData);
    setOpenExportV3Modal(true);
    dispatch(popIndicator(null));
  };

  return (
    <>
      {loggedInUser && (
        <main className="main-container ClientMeetingsPage">
          {!!loadingReason && <LoadingModal loadingReason={loadingReason} />}
          {!!errorReason && <ErrorModal errorReason={errorReason} />}
          {!loadingReason && !errorReason && (
            <>
              {clientDetails && (
                <div className="pageHeaderContainer">
                  <div
                    className="pageHeader"
                    onClick={() => history.push(`/clients`)}
                  >
                    <div className="back-arrow">
                      <FontAwesomeIcon
                        icon={[
                          "fal",
                          getLanguageDirection(i18n.language) === "ltr"
                            ? "angle-left"
                            : "angle-right",
                        ]}
                      />
                    </div>
                    <div className="clientName">{clientDetails?.name}</div>
                  </div>
                </div>
              )}
              <div className="clientInfoContainer">
                {clientDetails &&
                  clientsFields.map((clientField) => {
                    let field;
                    if (
                      clientField.type === "OnClickDropdown" &&
                      clientField.options
                    ) {
                      field = (
                        <OnClickDropdown
                          options={clientField.options}
                          placeholder={
                            clientField.placeholder
                              ? clientField.placeholder()
                              : clientDetails[clientField.fieldName]
                              ? t(clientDetails[clientField.fieldName])
                              : t("not_entered_yet")
                          }
                          onChange={async (
                            value: string[] | string | number | boolean
                          ) => {
                            await updateClientField(
                              value as string,
                              clientField.fieldName
                            );
                          }}
                          searchOption={false}
                          dropdownClassName="whiteBackground"
                          textClassName="EditableText inputContainer ellipsis"
                        ></OnClickDropdown>
                      );
                    } else if (
                      clientField.type === "EditableText" &&
                      clientField.inputType
                    ) {
                      field = (
                        <EditableText
                          type={clientField.inputType}
                          className="clientPage"
                          prefix={clientField.prefix ? clientField.prefix : ""}
                          value={
                            clientDetails[clientField.fieldName]
                              ? clientDetails[clientField.fieldName]
                              : t("not_entered_yet")
                          }
                          handleSetNewValue={(value: string | number) => {
                            return updateClientField(
                              value,
                              clientField.fieldName
                            );
                          }}
                          ellipsis={true}
                          showIcon={true}
                        />
                      );
                    }
                    return (
                      <LabelField
                        key={clientField.fieldName}
                        label={clientField.label}
                        className={clientField.className}
                      >
                        {field ? field : clientField.field}
                      </LabelField>
                    );
                  })}
              </div>
              <div className="validationsTable flex column">
                <h2>{t("validations")}</h2>
                <GenericTable
                  data={Object.keys(jobTypes).map((jobType) => ({
                    name: jobType,
                  }))}
                  config={{
                    tableName: "validations config",
                    columns: {
                      name: { label: t("name") },
                      action: { label: "" },
                    },
                    searchBar: false,
                  }}
                  onRowClick={(row) => {
                    setValidationsModalIsOpen(true);
                    setValidationModalJobType(row.name);
                  }}
                />
              </div>
              <div className="docxExportPresets flex column">
                <GenericTable
                  data={clientDocxPresets.map((p) => ({
                    ...p,
                    action: (
                      <Button
                        style={{
                          textTransform: "none",
                        }}
                        onClick={(e) => deletePreset(e, p.id)}
                        variant="outlined"
                        color="secondary"
                      >
                        {t("delete")}
                      </Button>
                    ),
                  }))}
                  config={{
                    searchBar: false,
                    tableName: "Docx Export Presets",
                    columns: {
                      name: { label: t("export_config_general_preset_name") },
                      action: { label: "" },
                    },
                    actions: {
                      exportConfig: {
                        label: "file-export",
                        icon: <FontAwesomeIcon icon={["far", "file-export"]} />,
                        action: (meetings) => {
                          history.push(
                            `/client/${id}/export-config/${"default"}`
                          );
                        },
                        bulk: false,
                        hidden: !FeatureFlagsService.isEnabled(
                          "exportConfigurationV3",
                          loggedInUser
                        ),
                      },
                    },
                  }}
                  onRowClick={(row) => {
                    history.push(`/client/${id}/export-config/${row.id}`);
                  }}
                />
              </div>
              <div className="meetings-page flex column align-center">
                {meetingsTableData.length > 0 && (
                  <div className="meetingsTable">
                    <MeetingsTable
                      additionalSearchFields={[
                        "job",
                        "lang",
                        "previewFormat",
                        "clientName",
                      ]}
                      data={
                        showArchivedJobs
                          ? meetingsTableData.filter((j) => j.status === 5)
                          : meetingsTableData.filter((j) => j.status !== 5)
                      }
                      rawData={meetings}
                      onRowClick={goToMeeting}
                      onSelection={handleJobSelection}
                      orderTable={orderTable}
                      config={{
                        multiSelect: true,
                        actions: {
                          exportSubtitles: {
                            label: "export-subtitles",
                            icon: (
                              <FontAwesomeIcon
                                icon={["far", "file-download"]}
                              />
                            ),
                            action: (selectedMeetings) => {
                              setOpenExportModal(true);
                              setActionMeetings(
                                selectedMeetings.map((selectedMeeting) => {
                                  return {
                                    id: selectedMeeting.id,
                                    name: selectedMeeting.name,
                                  };
                                })
                              );
                            },
                            bulk: false,
                            hidden: false,
                          },
                          export: {
                            label: "export",
                            icon: (
                              <FontAwesomeIcon icon={["far", "file-excel"]} />
                            ),
                            action: exportTable,
                            bulk: false,
                            hidden: loggedInUser?.role !== "super_user",
                          },
                          exportV3: {
                            label: "export_v3",
                            icon: (
                              <FontAwesomeIcon icon={["far", "file-export"]} />
                            ),
                            action: exportV3,
                            bulk: false,
                            hidden: false,
                          },
                          showArchive: {
                            label: "show_archived",
                            icon: <FontAwesomeIcon icon={["far", "archive"]} />,
                            action: () =>
                              setShowArchivedJobs((prevState) => !prevState),
                            bulk: false,
                            hidden: false,
                          },
                        },
                        tableColumns: [
                          "id",
                          "name",
                          "translation",
                          "deadline",
                          "meetingLength",
                          "creationTime",
                          "speakers",
                          "lang",
                          "previewFormat",
                          "price",
                          "status",
                          "actions",
                          "processProgress",
                          "job",
                          "member",
                          "process",
                          "contextMenu",
                          "invoiceSent",
                        ],
                        tableName: t("client_jobs"),
                        columns: {
                          status: { hidden: true },
                          clientName: {
                            hidden: true,
                          },
                          processProgressLastUpdate: {
                            hidden: loggedInUser?.role !== "super_user",
                          },
                          job: {
                            hidden:
                              loggedInUser?.role === "transcriber" ||
                              loggedInUser?.role === "proofer",
                          },
                          assignedTranscriber: {
                            hidden: loggedInUser?.role === "transcriber",
                          },
                          processProgress: {
                            hidden: loggedInUser.role !== "super_user",
                          },
                          price: {
                            hidden: loggedInUser.role === "super_user",
                          },
                          lang: {
                            hidden: true,
                          },
                          member: {
                            hidden: loggedInUser.role !== "super_user",
                          },
                          contextMenu: {
                            hidden: loggedInUser.role !== "super_user",
                          },
                        },
                        formatters: {
                          processProgress: (progress) =>
                            _.isNumber(progress) ? (
                              <CircleProgress progress={progress} />
                            ) : (
                              ""
                            ),
                        },
                      }}
                    />
                  </div>
                )}
              </div>
            </>
          )}
          {meetingsTableData.length === 0 && !loadingReason && !errorReason && (
            <EmptyPageComp title={t("no_client_meetings")} />
          )}
          {showModal && selectedMeeting && (
            <Dialog
              className={getLanguageDirection(i18n.language)}
              open={showModal}
              onClose={() => setShowModal(false)}
            >
              {(selectedMeeting.status === 3 &&
                !selectedMeeting.assignedTranscriber) ||
              (selectedMeeting.status === 4 &&
                !selectedMeeting.assignedProofer) ? (
                <TranscribersList
                  close={() => setShowModal(false)}
                  setStatusClr={_.noop}
                  loggedInUser={loggedInUser}
                  transcribers={(selectedMeeting.status === 3
                    ? transcribers
                    : proofers
                  ).filter((user) => user.role !== "disabled" && user.username)}
                  meetingName={selectedMeeting.name}
                  meetingStatus={selectedMeeting.status}
                  isLoading={isActionLoading}
                  handleAssignedTranscriber={(transcriber) => {
                    if (selectedMeeting.status === 3) {
                      assignTranscriberToMeeting(
                        selectedMeeting,
                        transcriber,
                        closeModal,
                        true
                      );
                    } else {
                      assignProoferToMeeting(
                        selectedMeeting,
                        transcriber,
                        closeModal,
                        true
                      );
                    }
                  }}
                />
              ) : (
                <ApproveModal
                  type={approvalType[selectedButton as ApprovalType]}
                  info={{ meetingName: selectedMeeting.name }}
                  approveBtn={{
                    text: t("approve"),
                    look: "neutral",
                    action: _.get(
                      onMeetingAction,
                      selectedButton as ApprovalType
                    ),
                  }}
                  declineBtn={{
                    text: t("cancel"),
                    look: "approve",
                    action: () => setShowModal(false),
                  }}
                  isPending={isActionLoading}
                  specificClass={"cancelAbsolute"}
                ></ApproveModal>
              )}
            </Dialog>
          )}
          {isEditModalOpen && selectedMeeting && (
            <EditModal
              handleSubmit={editJob}
              fieldsToEdit={[
                "name",
                "jobType",
                "lang.input",
                "lang.output",
                "price",
                "prooferPrice",
                "deadline",
                "processProgressLastUpdate",
              ]}
              job={selectedMeeting}
              showModal={isEditModalOpen}
              closeModal={() => setIsEditModalOpen(false)}
            ></EditModal>
          )}
          {selectedMeeting && isNoteModalOpen && (
            <NoteModal
              jobId={selectedMeeting.id}
              notes={selectedMeeting.notes || ""}
              showModal={isNoteModalOpen}
              closeModal={() => setIsNoteModalOpen(false)}
              onSubmit={async (note) => {
                await FirebaseService.updateNotes(note, selectedMeeting.id);
                selectedMeeting.notes = note;
                dispatch(updateMeeting(selectedMeeting));
                setIsNoteModalOpen(false);
              }}
            ></NoteModal>
          )}
          {meetingId && jobModalOpen && (
            <JobAuditModal
              id={meetingId}
              setShowModal={setJobModalOpen}
              showModal={jobModalOpen}
            ></JobAuditModal>
          )}
          {actionMeetings && openExportModal && (
            <ExportModal
              isOpen={openExportModal}
              setOpen={setOpenExportModal}
              meetings={actionMeetings}
              saveMeeting={false}
              frameRate={clientDetails.frameRate}
            ></ExportModal>
          )}
          {meetingId && translationJobModal && (
            <TranslationJobModal
              jobId={meetingId}
              showModal={translationJobModal}
              closeModal={() => setTranslationJobModal(false)}
            ></TranslationJobModal>
          )}
          {validationsModalIsOpen && validationModalJobType && (
            <ValidationsConfigModal
              setIsOpen={setValidationsModalIsOpen}
              isOpen={validationsModalIsOpen}
              jobType={validationModalJobType}
            ></ValidationsConfigModal>
          )}
          {selectedJobs.current.length > 0 &&
            openExportV3Modal &&
            exportConfig && (
              <ExportModalV3
                isOpen={openExportV3Modal}
                setOpen={setOpenExportV3Modal}
                jobsData={exportV3JobsData}
                exportConfig={exportConfig}
                presets={exportConfigPresets}
              />
            )}
        </main>
      )}
    </>
  );
};

export default ClientMeetingsPage;
