// This config file should not contain any sensitive information!

const project_name = "sumit-249611";
const project_id = "sumit-249611";
const messaging_sender_id = "136073597195";
const web_api_key = "AIzaSyAzaed6HrVfp_hrxcBO01zD4EOG7U9OITY";
const app_id = "1:136073597195:web:418e3ecbc0984f56";
const measurement_id = "G-29QQJZ8JFH";

module.exports = {
  environment: process.env.REACT_APP_ENV,
  isDev: false,
  enforceMfa: false,
  authProviders: true,
  mfaEnrollmentPath: "mfa",
  envUrl: "http://editor-v2.sumit-ai.com/",
  idleTime: 15 * 1000,
  workTimeSavingInterval: 30 * 1000,
  firebase: {
    apiKey: web_api_key,
    authDomain: `${project_id}.firebaseapp.com`,
    databaseURL: `https://${project_name}.firebaseio.com`,
    projectId: project_id,
    storageBucket: `gs://${project_id}.appspot.com`,
    messagingSenderId: messaging_sender_id,
    appId: app_id,
    measurementId: measurement_id,
  },
  cloudFunctions: {
    url: "https://us-central1-sumit-249611.cloudfunctions.net/",
    deliverToProof: "deliverToProof",
    json2subtitle: "json2subtitle",
    createJobsSubtitles: "createJobsSubtitles",
    createJobWords: "createJobWords",
    fixTimestamps: "fix_timestamps",
    getRoomPrice: "getRoomPrice",
    wordsSnapshot: "wordsSnapshot",
    jobSnapshot: "jobSnapshot",
    resetJob: "resetJob",
    wordsReset: "wordsReset",
    approveProofing: "approveProofing",
    disableUser: "disableUser",
    enableUser: "enableUser",
    getMyJobs: "getMyJobs",
    restoreJob: "restoreJob",
    importFile: "importFile",
    copyNotesFromParent: "copyNotesFromParent",
    createSubtitlesTranslationJob: "createSubtitlesTranslationJob",
    doneTextEnhancement: "doneTextEnhancement",
    getMergedSplitJob: "getMergedSplitJob",
    mergeJobs: "mergeJobs",
    debts: {
      addDebts: "debts-addDebts",
      completeJobDebts: "debts-completeJobDebts",
      getDebtsByUser: "debts-getDebtsByUser",
      addBonusToJobDebt: "debts-addBonusToJobDebt",
    },
    auth: {
      isExistingUser: "auth-isExistingUser",
      createUser: "auth-createUser",
      completeProviderSignup: "auth-completeProviderSignup",
      getMfaStatusByPhone: "auth-getMfaStatusByPhone",
      approveMultiFactor: "auth-approveMultiFactor",
    },
  },
  appEngine: {
    url: "https://sumit-249611.uc.r.appspot.com/",
    alignSubtitles: "align",
    jobSplit: "job_split",
    jobMerge: "job_merge",
    rerunStt: "rerun",
    editProgress: "edit_progress",
    embed: "embed",
    exportMarkers: "export_markers",
  },
  v3api: {
    url: "https://api.sumit-labs.com/",
    remap: "remap_times",
  },
  storage: {
    url:
      "https://firebasestorage.googleapis.com/v0/b/sumit-249611.appspot.com/",
    buckets: {
      translations: "o/translations%2F",
    },
  },
  appSearch: {
    publicKey: "Bearer search-sh6g1j9b4ye5mn59yctto421",
    url:
      "https://my-deployment-98b93d.ent.us-central1.gcp.cloud.es.io/api/as/v1/engines/",

    rooms: "production-rooms-v2",
  },
  streamManager: {
    url: "http://34.107.54.167:8081/",
    status: "cat",
    start: "start", //start?jobId=live-interview-demo
    stop: "stop",
  },
  mixpanel: {
    projectToken: "53c4e8192b643c04a70c2f96852a0cd5",
    debug: false,
  },
};
