import _ from "lodash";
import { UseFormReturn } from "react-hook-form";
import { JobType } from "../../models/jobTypes";

export type ValidationConfigPage = {
  name: string;
  slice: new (args: ValidationsFormPageSlice) => ValidationsFormPageSlice;
  component: JSX.Element;
};

export interface RawConfigValidation {
  [key: string]: ConfigValidationPageList;
}
export interface ConfigValidationPageList {
  [key: string]: ValidationConfigPage;
}

export interface ValidationsConfigData {
  [key: string]: ValidationsFormPageSlice;
}

export interface Validations {
  isLessThanFramesBetweenRanges?: {
    minFrames: number;
    frameLength: number;
  };
  isLessThanFrames?: {
    minFrames: number;
    frameLength: number;
  };
  isMoreThanCharsInRange?: {
    maxCharsInRange: number;
  };
  isMoreThanLines?: {
    maxLines: number;
  };
  isMoreThanCharsInLine?: {
    maxCharsInLine: number;
  };
  isMoreThanCharsPerSecond?: {
    maxChars: number;
  };
  isMoreThanSeconds?: {
    maxSeconds: number;
  };
}

export type ValidationsConfigContext = "client" | "global";

export type KeyOfValidationsConfigData = keyof ValidationsConfigData;

export type KeyOfValidationsFormPageSlice = keyof ValidationsFormPageSlice;

const MAX_FONT_SIZE = 22;
const MIN_FONT_SIZE = 12;

export class ValidationsFormPageSlice {
  isLessThanDynamic:
    | {
        minSeconds: number | string;
        minFrames: number | string;
      }
    | false = {
    minSeconds: 1,
    minFrames: 10,
  };

  isMoreThanCharsInLineDynamic:
    | {
        maxCharsInLine: { lineOne: number; lineTwo: number };
      }
    | false = {
    maxCharsInLine: { lineOne: 50, lineTwo: 50 },
  };

  isLessThanFramesBetweenRanges:
    | {
        minFrames: number | string;
        frameLength: number | string;
      }
    | false = {
    minFrames: 1,
    frameLength: 10,
  };

  isLessThanFrames:
    | {
        minFrames: number | string;
        frameLength: number | string;
      }
    | false = {
    minFrames: 1,
    frameLength: 10,
  };
  isMoreThanCharsInRange:
    | {
        maxCharsInRange: number | string;
      }
    | false = {
    maxCharsInRange: 90,
  };
  isMoreThanLines:
    | {
        maxLines: number | string;
      }
    | false = {
    maxLines: 2,
  };
  isMoreThanCharsInLine:
    | {
        maxCharsInLine: number | string;
      }
    | false = {
    maxCharsInLine: 45,
  };
  isMoreThanSeconds:
    | {
        maxSeconds: number | string;
      }
    | false = {
    maxSeconds: 8,
  };

  constructor(args?: ValidationsFormPageSlice) {
    if (args && typeof args !== undefined) {
      Object.assign(this, _.merge(this, args));
    }
  }
}

export const defaultValidationConfig = new ValidationsFormPageSlice();

export const fontSizeOptions = _.times(MAX_FONT_SIZE + 1, (num: number) => ({
  label: `${num}`,
  value: num,
})).slice(MIN_FONT_SIZE, MAX_FONT_SIZE + 1);

export interface SettingPagesProps<T> {
  availableValidations: { [v: string]: { [k: string]: "number" | "text" } };
  formValues?: ValidationsFormPageSlice;
  lang: string;
  handleFormChange: (
    formValues: ValidationsFormPageSlice,
    lang: KeyOfValidationsConfigData
  ) => void;
}
