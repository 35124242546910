import React, { FC } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { JobData } from "../../models/job";
import { LoggedInUser } from "../../models/user";

interface Props {
  job: JobData;
  user: LoggedInUser;
}

const JobInfoPanel: FC<Props> = ({ job, user }) => {
  const { t } = useTranslation();

  return (
    <div className="JobInfoPanel">
      <div className="infoPanel">
        <div className="jobName jobInfoItem">{job.name}</div>
        {user.role === "super_user" && job.transcriberName && (
          <div className="jobTranscriber jobInfoItem">
            <FontAwesomeIcon
              className="transcriberIcon"
              icon={["fal", "user-headset"]}
            />
            <Link
              to={{
                pathname: `/transcriber/${
                  _.isString(job.transcriber)
                    ? job.transcriber
                    : job.transcriber?.id
                }`,
                state: { name: "back_to_meeting" },
              }}
            >
              {job.transcriberName}
            </Link>
          </div>
        )}
        {user.role === "super_user" && job.prooferName && (
          <div className="jobProofer jobInfoItem">
            <FontAwesomeIcon
              className="prooferIcon"
              icon={["fal", "user-check"]}
            />
            <div className="prooferName">
              <Link
                to={{
                  pathname: `/transcriber/${
                    _.isString(job.proofer) ? job.proofer : job.proofer?.id
                  }`,
                  state: { name: "back_to_meeting" },
                }}
              >
                {job.prooferName}
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default JobInfoPanel;
