import React, { FC, useEffect } from "react";
import _ from "lodash";
import { useSelector } from "react-redux";
import { AppState } from "../../store/rootReducer";
import { useTranslation } from "react-i18next";
import LazyLoad from "react-lazyload";

import Logger from "../../services/Logger";
import EditorService from "../../services/EditorService";
import FeatureFlagsService from "../../services/FeatureFlagsService";

import { Word } from "../../models";
import { JobData } from "../../models/job";
import { SubtitlesTranslationRange } from "../../models/range";

import {
  focusAndSetCursor,
  scrollInto,
  scrollToOffset,
} from "../../utils/focusAndScroll";
import RangeSubtitlesTranslate from "../../components/TextRange/RangeSubtitlesTranslate";

import "./SubtitlesTranslation.scss";
import { Action } from "../../models/editor";

const logger = Logger("SubtitlesTranslation");

interface Props {
  job: JobData;
  ranges: SubtitlesTranslationRange[];
  updateRanges: (
    ranges: SubtitlesTranslationRange[],
    rangeIndex?: number
  ) => void;
  focusedRangeIndex: number;
  setFocusedRangeIndex: (rangeIx: number) => void;
  currentTime: number;
  editorDirection: "rtl" | "ltr";
  addActions: (actions: Action[]) => void;
}

const SubtitlesTranslation: FC<Props> = ({
  job,
  ranges,
  updateRanges,
  setFocusedRangeIndex,
  currentTime,
  editorDirection,
  addActions,
}): JSX.Element => {
  const { t } = useTranslation();

  const loggedInUser = useSelector(
    (state: AppState) => state.userStore.loggedInUser
  );

  useEffect(() => {
    addActions([]);
    if (FeatureFlagsService.isEnabled("saveUserLastPosition", loggedInUser)) {
      const {
        cursorPosition,
        rangeIx,
        scrollOffsetTop,
      } = EditorService.getLastPosition(job.roomId);
      scrollToOffset("editorContainer", scrollOffsetTop);
      focusAndSetCursor(rangeIx, cursorPosition);
    }
  }, []);

  // KeyPress Handlers
  const onPressEnter = ({
    rangeIndex,
    event,
  }: {
    rangeIndex: number;
    event: React.KeyboardEvent;
  }) => {
    if (!event.shiftKey) {
      scrollInto(`range-${rangeIndex + 1}`);
      focusAndSetCursor(rangeIndex + 1, 0);
    }
  };
  // KeyPress Handlers

  const updateRangeWords = (rangeIndex: number, rangeWords: Word[]) => {
    const updatedRanges = _.clone(ranges);
    const updatedRange = { ...ranges[rangeIndex], words: rangeWords };
    _.set(updatedRanges, `[${rangeIndex}]`, updatedRange);

    updateRanges(updatedRanges, rangeIndex);
  };

  return (
    <div className="SubtitlesTranslationEditor">
      <div className="titles">
        <div className="title">
          <div className="rangesType">{`(${t("target")})`}</div>
          <div className="lang">:{t(job.lang.output[0])}</div>
        </div>
        <div className="title">
          <div className="rangesType">{`(${t("source")})`}</div>
          <div className="lang">:{t(job.lang.input[0])}</div>
        </div>
      </div>
      <div
        id="editorContainer"
        className="editorRanges subtitlesTranlationRanges"
      >
        {ranges.map((range, rangeIndex) => (
          <LazyLoad
            height={100}
            once
            offset={
              // Determain divs height by its word count
              ((range.sourceWords && range.sourceWords.length * 1.3) || 400) +
              1000
            }
            scrollContainer="#editorContainer"
            unmountIfInvisible={true}
            key={range.id}
          >
            <RangeSubtitlesTranslate
              job={job}
              ranges={ranges}
              range={range}
              updateRangeWords={updateRangeWords}
              onPressEnter={onPressEnter}
              isCurrentPlayingRange={
                Number(currentTime) > Number(range.st) &&
                Number(currentTime) < Number(range.et)
              }
              isPassed={Number(currentTime) > Number(range.et)}
              rangeIndex={rangeIndex}
              setFocusedRangeIndex={setFocusedRangeIndex}
              direction={editorDirection}
              disabled={false}
            />
          </LazyLoad>
        ))}
      </div>
    </div>
  );
};

export default SubtitlesTranslation;
